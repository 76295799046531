//import { DataGrid } from '@scanex/datagrid';
import EventTarget from '@scanex/event-target';
//import * as Translations from '@scanex/translations';
//import {ColorPicker, hex2rgb, rgb2hsl } from '@scanex/color-picker';
//import { createContainer } from 'utils/CommonUtils.js';

class DrawnObjects extends EventTarget {
    constructor (container, { color = '#0000FF'}){        
        super();

        this._commonVisible = true;

        this._count = 0;
        this._container = container;
        //this._container.style.display = 'none';
        this._container.innerHTML =
            `<div class="adv-objects">
                <span class="drawn-objects-minimize"></span>
                <div class="drawn-objects-label">
                    <span class="drawn-objects-none"></span>
                    <label ></br>При заказе на search.kosmosnimki.ru</br> </label>
                    <span class="colortext">скидка 5%</span>
                    <label>на все данные</label>
                </div>
            </div>
            <div class="adv-min-icon">
                <i class="icon icon-adv" style="margin-right: 40px; width: 26px;height: 26px;display: block;cursor: pointer;">
            </div>`;
        this._container.querySelector('.adv-min-icon').style.display = 'none';
        this._container.querySelector('.adv-objects').style.display = 'block';
        this._toggle = this._toggle.bind(this);

        this._container.querySelector('.drawn-objects-minimize').addEventListener('click', this._toggle);
        this._container.querySelector('.adv-min-icon').addEventListener('click', this._toggle);

    }
    _toggle (e) {
        var content = this._container.querySelector('.adv-objects');
        content.style.display = content.style.display === 'none' ? 'block' : 'none';
        var content2 = this._container.querySelector('.adv-min-icon');
        content2.style.display = content2.style.display === 'none' ? 'block' : 'none';
    }

}

export default class View {

    constructor({ application, map, position }){

        this._application = application;

        const DrawnObjectsControl = L.Control.extend ({
            includes: L.Evented ? L.Evented.prototype : L.Mixin.Events,
        
            // options.position (left|right)
            initialize: function(options) {    
                L.setOptions(this, options);
            },
        
            onAdd: function() {
                this._container = L.DomUtil.create('div', 'drawn-objects-control');
                this.widget = new DrawnObjects(this._container, {});
                // L.DomEvent.disableClickPropagation(this._container);
                // L.DomEvent.disableScrollPropagation(this._container);
                // L.DomEvent.on(this._container, 'mousemove', L.DomEvent.stopPropagation);
                return this._container;
            },    
        
            onRemove: function(map) { },

            resizeDrawings() {

                const app = application;
        
                const { height } =  app.getMapContainer().getBoundingClientRect();
                this.widget.resize(height - 150);
            }
        });

        const drawingView = new DrawnObjectsControl({
            position
        });

        map.addControl(drawingView);

        this._main = drawingView;

        return drawingView;
    }

}