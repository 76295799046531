import BaseCompositedComponent from 'base/BaseCompositedComponent.js';
import * as Translations from '@scanex/translations';
import HeaderComponent from './components/header/HeaderComponent.js';
import ListComponent from './components/list/ListComponent.js';
import TableHeaderComponent from './components/tableHeader/TableHeaderComponent.js';
import { TAB_RESULTS_NAME } from 'config/constants/Constants.js';

// import View from './view/View';

export default class ResultTabComponent extends BaseCompositedComponent {

    init() {

        const options = {
            id: 'results',            
            icon: 'sidebar-results',
            opened: 'sidebar-results-opened',
            closed: 'sidebar-results-closed',
            tooltip: Translations.translate('results.title')
        };
        this._view = this.getParentComponent().getView().addTab('results');             

        this.initChildren([
            {
                index: 'header',
                constructor: HeaderComponent
            },
            {
                index: 'tableHeader',
                constructor: TableHeaderComponent
            },
            {
                index: 'list',
                constructor: ListComponent
            }
        ]);

        this._bindEvents();
    }

    _bindEvents() {

        const application = this.getApplication();
        const store = application.getStore();
        const tableHeader = this.getChildComponent('tableHeader');
        const list = this.getChildComponent('list');

        store.on('currentTab:changeAfter', () => this._onAfterTabChangeHandler());
        tableHeader.events.on('sortGrid', (sortData) => list.sortGrid(sortData));
    }

    _onAfterTabChangeHandler() {

        const application = this.getApplication();
        const store = application.getStore();
        const currentTab = store.getMetaItem('currentTab');
        const resultsUpdated = store.getMetaItem('updateResults');

        if (currentTab === TAB_RESULTS_NAME && resultsUpdated) {
            store.setMetaItem('updateResults', false);
        }
    }

}