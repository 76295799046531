import * as Translations from '@scanex/translations';

Translations.add ('ru', {
    cart: {
        title: 'Корзина',
        clear: 'Очистить корзину',
        back: 'Назад',
        order: 'Перейти к оформлению заказа',        
        warning: 'Для редактирования контактной информации воспользуйтесь <label class="link">ссылкой</label>.\r\nПосле этого необходимо снова зайти в систему.',
        customer: 'Организация (заказчик)',
        project: {
            name: 'Название проекта',
            type: {
                title: 'Тип проекта',
                commercial: 'Коммерческий',
                internal: 'Внутренний',
                presale: 'Пресейл',
            },
            number: '№ Договора-контракта',
        },
        person: 'Имя и фамилия',        
        company: 'Компания',
        email: 'Электронная почта',
        comment: 'Комментарий',
        header: 'Оформление заказа',
        submit: 'Заказать',
        close: 'Закрыть',
        success: {
            header: 'Благодарим за оформление заказа!',
            content: 'Статус Вашего заказа необходимо подтвердить.',
            footer: 'Детали заказа и инструкции для его подтверждения отправлены на Ваш адрес электронной почты.',
        },
        invalid: 'Данное поле обязательно для заполнения'
    }
});

Translations.add ('en', {
    cart: {
        title: 'Cart',
        clear: 'Clear',
        back: 'Back',
        order: 'To order',        
        warning: 'To edit contact information use the link #. Afterwards it is necessary to login.',
        customer: 'Organization (customer)',
        project: {
            name: 'Project name',
            type: {
                title: 'Project type',
                commercial: 'Commercial',
                internal: 'Internal',
                presale: 'Presale',
            },
            number: 'Project number',
        },
        person: 'Name',
        company: 'Company',
        email: 'Email',
        comment: 'Comment',
        header: 'Place an order',
        submit: 'Submit',
        close: 'Close',
        success: {
            header: 'Thank you for using Scanex Catalog!',
            content: 'Please confirm the status of your order.',
            footer: 'Order details and instructions were emailed to you.',
        },
        invalid: 'This field is required'
    }
});