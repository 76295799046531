<script>

    import * as Translations from '@scanex/translations';
    import {createEventDispatcher} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let isHidden = true;
    export let downloadType = 'borders';
    export let fileName = translate('download.noname');

    export const getType = () => downloadType;
    export const getName = () => fileName;            
    export const show = () => isHidden = false;        
    export const hide = () => isHidden = true;

</script>

<div class="dialog-download" style="top: 45px; left: 475px; display:{isHidden?'none':'block'};">
    <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
            <tr>
                <td class="download-type">{translate('download.type')}</td>
                <td>
                    <select bind:value="{downloadType}">
                        <option value="borders">{translate('download.borders')}</option>
                        <option value="results">{translate('download.results')}</option>
                        <option value="rcsv">{translate('download.rcsv')}</option>
                        <option value="cart">{translate('download.cart')}</option>
                        <option value="ccsv">{translate('download.ccsv')}</option>
                        <option value="quicklooks">{translate('download.quicklooks')}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="download-file">{translate('download.file')}</td>
                <td>
                    <input type="text" bind:value="{fileName}" />
                </td>
            </tr>
            <tr>
                <td colspan="2" class="download-footer">
                    <button on:click|stopPropagation="{() => dispatch('apply')}" class="download-ok">{translate('download.ok')}</button>
                    <button on:click|stopPropagation="{() => dispatch('cancel')}" class="download-cancel">{translate('download.cancel')}</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>