import BaseComponent from 'base/BaseComponent.js';
import { createContainer } from 'utils/CommonUtils.js';
import View from './view/View.svelte';

export default class DialogComponent extends BaseComponent {

    init() {

        this._view = new View({
            target: createContainer()
        })

        this._bindEvents();
    }

    _bindEvents() {

        const view = this.getView();

        view.$on('apply', () => {
            this.events.trigger('click:apply');
        });
        view.$on('cancel', () => {
            this.events.trigger('click:cancel');
        });
    }

    show() {

        const view = this.getView();
        view.show();
    }

    hide() {

        const view = this.getView();
        view.hide();
    }

    getType() {

        const view = this.getView();
        return view.getType();
    }

    getName() {

        const view = this.getView();
        return view.getName();
    }

}