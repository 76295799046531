import * as Translations from '@scanex/translations';
import BaseUIElement from 'base/BaseUIElement.js';
import {
    RESULT_MAX_COUNT_PLUS_ONE,
    ACCESS_USER_ROLE,
    HOME_LINK
} from 'config/constants/Constants.js';
import {getTotalHeight, getRootUrl} from 'utils/CommonUtils.js';
import {getProperty} from 'application/searchDataStore/SearchDataStore.js';
import SearchTabComponent from './components/searchTab/SearchTabComponent.js';
import ResultsTabComponent from './components/resultsTab/ResultsTabComponent.js';
import FavoritesTabComponent from './components/favoritesTab/FavoritesTabComponent.js';
import ImageDetailsComponent from './components/imageDetails/ImageDetailsComponent.js';
import OrdersTabComponent from './components/ordersTab/OrdersTabComponent.js';
import View from './view/View.js';
import DownloadDialog from './dialogs/DownloadDialog.svelte';
import OrderDialog from './dialogs/OrderDialog.svelte';

const translate = Translations.translate.bind(Translations);

export default class SidebarUIElement extends BaseUIElement {

    init() {

        const map = this.getMap();
        const application = this.getApplication();
        const store = application.getStore();

        this._view = new View({ map });

        let children = [
            {
                index: 'searchTab',
                constructor: SearchTabComponent
            },
            {
                index: 'resultsTab',
                constructor: ResultsTabComponent
            },
            {
                index: 'favoritesTab',
                constructor: FavoritesTabComponent
            },
            {
                index: 'imageDetails',
                constructor: ImageDetailsComponent
            }
        ];

        const {IsCustomer} = store.getData('userInfo');

        if (IsCustomer) {
            children.push ({
                index: 'ordersTab',
                constructor: OrdersTabComponent
            });
        }

        this.initChildren(children);        

        this._manageTabState('start');

        this._bindEvents();
    }

    _bindEvents() {

        const application = this.getApplication();
        const serviceEvents = application.getServiceEvents();
        const globalEvents = application.getAppEvents();
        const store = application.getStore();
        const {gmxDrawing} = application.getMap();
        const contourController = application.getBridgeController('contour');
        const searchTabComponent = this.getChildComponent('searchTab');
        const resultsHeaderComponent = this.getChildComponent('resultsTab.header');
        const resutsListComponent = this.getChildComponent('resultsTab.list');
        const tableHeaderComponent = this.getChildComponent('resultsTab.tableHeader');
        const favoritesTabComponent = this.getChildComponent('favoritesTab');
        const favoritesListComponent = this.getChildComponent('favoritesTab.list');
        const sidebarView = this.getView();

        sidebarView.on('change', (e) => this._onTabChangeHandler(e));

        gmxDrawing.on('drawstop', () => this._manageTabState('stopDrawing'));

        store.on('contours:researchedList', () => this._manageTabState('addToResults'));
        store.on('contours:addToCartList', () => this._manageTabState('addToFavorites'));
        store.on('contours:addAllToCartList', () => this._manageTabState('addToFavorites'));
        store.on('contours:addVisibleToFavoritesList', () => this._manageTabState('addToFavorites'));
        store.on('contours:removeSelectedFavoritesList', () => this._manageTabState('clearFavorites'));

        globalEvents.on('system:window:resize', () => this._resizeSidebar());
        globalEvents.on('system:uiElements:created', () => this._resizeSidebar());
        serviceEvents.on('sidebar:cart:limit', () => this._cartLimitMessage());
        serviceEvents.on('permalink:searchResults', () => this._searchResults());
        serviceEvents.on('sidebar:setCurrentTab', (tab) => this._manageTabState('applyAppState', tab));

        searchTabComponent.events.on('searchButton:click', () => this._searchResults());
        resultsHeaderComponent.events.on('results:clear', () => contourController.clearContoursOnResults());
        resultsHeaderComponent.events.on('filter:clear', () => contourController.clearClientFilter());
        resultsHeaderComponent.events.on('results:setVisibleToCart', () => contourController.setVisibleToCart());
        resutsListComponent.events.on('imageDetails:show', (e, bBox) => this._showImageDetails(e, bBox));
        //resutsListComponent.events.on('filter:change', (e) => contourController.changeClientFilter(e));
        tableHeaderComponent.events.on('filter:change', e => contourController.changeClientFilter(e));
        favoritesListComponent.events.on('imageDetails:show', (e, bBox) => this._showImageDetails(e, bBox));
        favoritesTabComponent.events.on('makeOrder:click', () => this._showOrderDialog());
    }

    _onTabChangeHandler(e) {

        const application = this.getApplication();
        const store = application.getStore();
        const sidebarView = this.getView();

        store.setMetaItem('currentTab', sidebarView.getCurrent(), [
            'currentTab:changeUI',
            'currentTab:changeMap',
            'currentTab:changeMeta',
            'currentTab:changeAfter'
        ]);

        this._changeTabBorder(e);
    }

    _searchResults() {

        const application = this.getApplication();
        const store = application.getStore();
        const requestManager = application.getRequestManager();
        const contourController = application.getBridgeController('contour');

        const searchCriteria = store.getData('searchCriteria');
        const {satellites: {pc = [], ms = []}} = searchCriteria;

        const hasCheckedSatellites = ms.some(x => x.checked) || pc.some(x => x.checked);

        if (!hasCheckedSatellites) {
            return false;
        }

        application.showLoader(true);

        contourController.clearClientFilter();

        contourController.clearContoursOnResults();

        requestManager.requestSearchContours(RESULT_MAX_COUNT_PLUS_ONE)
        .then(this._setContoursData.bind(this))
        .catch(this._showError.bind(this));
    }

    _setContoursData(result = {}) {

        const application = this.getApplication();
        const store = application.getStore();
        const ContourController = application.getBridgeController('contour');
        const isLoadingCancelled = store.getMetaItem('cancelLoading');

        if (!isLoadingCancelled) {

            const {values = []} = result;
            const resultLength = values.length;

            if (resultLength === 0) {
                const alertNothingMessage = Translations.translate('alerts.nothing');
                application.showNotification(alertNothingMessage);
            }
            else if (0 < resultLength && resultLength < RESULT_MAX_COUNT_PLUS_ONE) {
                ContourController.addContoursOnMapAndList(result);
            }
            else {
                this._showDownloadDialog();
            }
        }

        application.showLoader(false);

        store.setMetaItem('cancelLoading', false);
    }

    _showDownloadDialog() {

        const application = this.getApplication();
        const modalComponent = application.getModal();

        modalComponent.show({
            component: DownloadDialog,
            data: { isAuthentificated: this._isUserIsAuthenticated() },
            events: {
                close: () => modalComponent.hide(),
                apply: () => {
                    modalComponent.hide();
                    this._onDownloadApplyClick();
                }
            }
        });
    }

    _showOrderDialog() {

        const application = this.getApplication();
        const store = application.getStore();
        const modalComponent = application.getModal();
        const appStateManager = application.getAddon('appStateManager');

        const showModal = data => {
            modalComponent.show({
                data,
                showClose: true,
                component: OrderDialog,
                events: {
                    login: this._onLoginButtonClick.bind(this),
                    warning: this._onWarningClick.bind(this),
                    submit: this._onSubmitClick.bind(this),
                    close: () => modalComponent.hide()
                }
            });
        };

        const userInfo = store.getData('userInfo');
        const inputValues = { ...userInfo, customer: '', project: '', number: '', comment: '' };
        const isAuthed = this._isUserIsAuthenticated();
        const isInternal = this._isUserIsInternal();
        const selectedCarts = store.getSelectedFavorites();

        if (selectedCarts.length < 1) {
            return;
        }

        let orderData = {
            inputValues,
            isAuthed,
            isInternal,
            items: selectedCarts.map(item => getProperty(item, 'sceneid')).join(','),
            scenesList: JSON.stringify(selectedCarts.map(item => {
                const sceneid = getProperty(item, 'sceneid');
                const platform = getProperty(item, 'platform');
                const sensor = getProperty(item, 'sensor');
                const isLocal = getProperty(item, 'islocal');
                const spot5AExists = getProperty(item, 'spot5_a_exists');
                const spot5BExists = getProperty(item, 'spot5_b_exists');
                const product = getProperty(item, 'product');
                const getPlatformId = () => {
                    switch(platform) {
                        case 'WV01':
                        case 'WV02':
                        case 'WV03':
                        case 'WV04':
                        case 'GE01':
                        case 'QB02':
                        case 'KOMPSAT2':
                        case 'KOMPSAT3':
                        case 'KOMPSAT3A':
                        case 'GJ1A':
                        case 'GJ1B':
                        case 'GJ1C':
                        case 'GJ1D':
                        case 'GF2':
                        case 'GF4':                        
                        case 'Аист-2Д':
                        case 'BKA':
                        case 'RE':
                        case 'LANDSAT8':
                            return platform;                        
                        case 'PHR1A':
                        case '1A_PM':
                        case 'PHR-1A':
                            return 'PHR-1A';
                        case 'PHR1B':                        
                        case '1B_PM':                        
                        case 'PHR-1B':
                            return 'PHR-1B';
                        case 'IK-2':
                        case 'IKONOS-2':
                            return 'IK-2';
                        case 'Ресурс-П1':
                        case 'Ресурс-П2':
                        case 'Ресурс-П3':
                            if (spot5AExists && spot5BExists) {
                                return `${platform}-1`;
                            }
                            if (spot5BExists) {
                                return `${platform}-4`;
                            }
                            if (spot5AExists && !spot5BExists) {
                                return `${platform}-П`;
                            }
                            return platform;
                        case 'TripleSat Constellation-1':
                            return 'TripleSat-1';
                        case 'TripleSat Constellation-2':
                            return 'TripleSat-2';
                        case 'TripleSat Constellation-3':
                            return 'TripleSat-3';
                        case 'SPOT6':
                        case 'SPOT 6':
                            if (isLocal) {
                                return 'SPOT6A';
                            }
                            if (product) {
                                return 'SPOT6-P';
                            }
                            return 'SPOT6';
                        case 'SPOT7':
                        case 'SPOT 7':
                            if (isLocal) {
                                return 'SPOT7A';
                            }
                            if (product) {
                                return 'SPOT7-P';
                            }
                            return 'SPOT7';
                        case 'GF1':
                            switch (sensor) {
                                case 'A':
                                case 'B':
                                    return `GF1-${sensor}`;
                                default:
                                    return 'GF1';
                            }
                        case 'ZY3':
                        case 'ZY302':
                            return 'ZY3';
                        case 'Канопус-В1':
                        case 'Канопус-В-ИК':
                        case 'Канопус-В3':
                        case 'Канопус-В4':
                            if (sensor === 'МСС') {
                                return platform;
                            }
                            if (sensor === 'ПСС') {
                                return `${platform}-П`;
                            }
                            return platform;
                        case 'SPOT 5':
                            if (sensor === 'J') {
                                return 'SPOT5-10';
                            }
                            if (sensor === 'J' && (spot5AExists || spot5BExists)) {
                                return 'SPOT5-5';
                            }
                            if (sensor === 'A' || sensor === 'B' && !spot5BExists) {
                                return 'SPOT5-5-PAN';
                            }
                            if (sensor === 'J' && spot5AExists && spot5BExists) {
                                return 'SPOT5-2';
                            }
                            if (sensor === 'A' && spot5BExists) {
                                return 'SPOT5-2-PAN';
                            }
                            return 'SPOT5';
                    }
                };
                return {
                    sceneId: sceneid,
                    platformId: getPlatformId(),
                };
            })),
        };

        if (!isAuthed) {
            showModal(orderData);
            return;
        }

        appStateManager.getPermalinkId()
        .then(result => {
            orderData['permalink'] = `${getRootUrl()}?link=${result}`;
            showModal(orderData);
        })
        .catch(e => this._showError(e))
    }

    _onLoginButtonClick() {

        const application = this.getApplication();
        const appStateManager = application.getAddon('appStateManager');
        const authContainer = document.getElementById('auth');
        const loginButton = authContainer.querySelector('.authWidget-loginButton');

        const currentAppState = appStateManager.getCurrentApplicationState();
        const savedState = appStateManager.getAppStateFromLocalStorage();
        if (!savedState) {
            appStateManager.saveAppStateToLocalStorage(currentAppState);
        }

        loginButton.click();
    }

    _onWarningClick(permalink) {

        const application = this.getApplication();
        const appStateManager = application.getAddon('appStateManager');
        const matches = /link=([^&]+)/g.exec(permalink);

        if (Array.isArray (matches) && matches.length > 0) {
            const permalinkId = matches[1];
            appStateManager.readPermalink(permalinkId)
            .then (response => {
                appStateManager.saveAppStateToLocalStorage(response);
                window.location = HOME_LINK;
            })
            .catch(console.log);
        }
        else {
            console.log(`Permalink not set: ${permalink}`);
        }
    }

    _onSubmitClick(data, dialogView) {

        const application = this.getApplication();
        const modalComponent = application.getModal();
        const requestManager = application.getRequestManager();
        
        requestManager.requestCreateOrder(data)
        .then(response => {

            const {Status: status} = response;

            if (status === 'ok') {                    
                dialogView.showSuccess();
            }
            else {                
                console.log(response);
                modalComponent.hide();
                alert(translate('errors.general'));
            }
        })
        .catch(error => {            
            console.log(error);
            alert(translate('errors.general'));
        });
    }

    _isUserIsAuthenticated() {

        const application = this.getApplication();
        const store = application.getStore();
        const userInfo = store.getData('userInfo');

        return userInfo['IsAuthenticated'];
    }

    _isUserIsInternal() {

        const application = this.getApplication();
        const store = application.getStore();
        const userInfo = store.getData('userInfo');

        return userInfo['IsAuthenticated'] && (userInfo['Role'] === ACCESS_USER_ROLE || userInfo['Role'] === "admin");
    }

    _resizeSidebar() {

        const application = this.getApplication();
        const events = application.getServiceEvents();
        const height = getTotalHeight([ '#header', '.leaflet-gmx-copyright' ]);
        const preparedHeight = document.body.getBoundingClientRect().height - height;        

        this.getView().getContainer().style.height = `${preparedHeight}px`;
        events.trigger('sidebar:tab:resize', preparedHeight);
    }

    _changeTabBorder(e) {
        
        const tabs = document.querySelectorAll('.tabs > div');

        tabs.forEach(tab => tab.classList.remove('active-sidebar-tab'));
        const sidebarView = this.getView();
        const current = sidebarView.getCurrent();
        if (current) {            
            const currentTab = sidebarView.getTab(current);
            currentTab.classList.add('active-sidebar-tab');
        }
    }

    _showImageDetails(e, bBox) {

        const imageDetailsComponent = this.getChildComponent('imageDetails');
        imageDetailsComponent.toggle(e, bBox);
    }

    _cartLimitMessage() {

        const application = this.getApplication();
        const modalComponent = application.getModal();

        modalComponent.show({
            component: 'alert',
            messageText: Translations.translate('favorites.limit')
        });
    }

    _onDownloadApplyClick() {

        const application = this.getApplication();
        const store = application.getStore();
        const requestManager = application.getRequestManager();
        const shapeLoader = application.getAddon('shapeLoader');

        store.setMetaItem('cancelLoading', false);
        
        application.showLoader(true);

        requestManager.requestSearchContours()
        .then ((data) => {
            application.showLoader(false);

            const cancelLoading = store.getMetaItem('cancelLoading');

            if (!cancelLoading) {
                store.setDownloadCache(data);
                shapeLoader.download('results', 'results');
            }
        })
        .catch(this._showError.bind(this));
    }

    _manageTabState(state, tabName = false) {

        const application = this.getApplication();
        const store = application.getStore();
        const sidebar = this.getView();
        const imageDetailsComponent = this.getChildComponent('imageDetails');
    
        const hasResultData = store.hasResults();
        const hasFavoritesData = store.hasFavorites();

        imageDetailsComponent.hide();
    
        if (state === 'start') {
            sidebar.disable('results');
            sidebar.disable('favorites');
            return;
        }

        if (state === 'applyAppState') {
            if (hasResultData) {
                sidebar.enable('results');
            }
            if (hasFavoritesData) {
                sidebar.enable('favorites');
            }
            sidebar.setCurrent(tabName);
            return;
        }
    
        if (state === 'stopDrawing') {            
            const visible = sidebar.getVisible();
            if (!visible) {
                sidebar.setCurrent('search');
            }
            return;
        }
    
        if (state === 'clearResults') {
            sidebar.disable('results');
            sidebar.setCurrent('search');
            return;
        }
    
        if (state === 'addToResults') {
            if (hasResultData) {
                sidebar.enable('results');
                sidebar.setCurrent('results');
            }
            else {
                sidebar.disable('results');
                sidebar.setCurrent('search');
            }
            return;
        }
    
        if (state === 'addToFavorites') {
            if (hasFavoritesData > 0) {
                sidebar.enable('favorites');
            }
            else {
                sidebar.disable('favorites');
                sidebar.setCurrent('results');
            }
            return;
        }
    
        if (state === 'clearFavorites') {
            if (hasFavoritesData) {
                sidebar.enable('favorites');
                sidebar.setCurrent('favorites');
            }
            else {
                sidebar.disable('favorites');
                const currentTab = hasResultData ? 'results' : 'search';
                sidebar.setCurrent(currentTab);
            }
            return;
        }
    }

    _showError(e) {

        const application = this.getApplication();
        const errorText = e.toString();

        application.showError(errorText);

        window.console.error(e);
    }

}