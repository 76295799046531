<script>

    import { RangeWidget } from '@scanex/slider-widget';
    import * as Translations from '@scanex/translations';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let hidden = true;
    export let left = 0;
    export let top = 0;
    export let width = 0;
    export let resolution = 0;
    export let swath = 0;
    export let since = 0;
    export let operator = '';

    $: correctLeft = left + width + 30;
    $: correctTop = top - 100;

</script>

<div
    class="satellite-info"
    style="display:{hidden?'none':'block'}; left:{correctLeft}px; top:{correctTop}px"
>
    <table cellspacing="0" cellpadding="0">
        <tbody>
            <tr>
                <td>{translate('satellite.resolution')}:</td><td>{resolution}</td><td>{translate('resolution.unit')}</td>
            </tr>
            <tr>
                <td>{translate('satellite.swath')}:</td><td>{swath}</td><td>{translate('units.km')}</td>
            </tr>                
        </tbody>
    </table>
    <div>{operator}</div>
    <div>{translate('satellite.since')} {since}</div>
</div>