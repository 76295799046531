<script>
    
    import EventTarget from '@scanex/event-target';
    import * as Translations from '@scanex/translations';
    import Pikaday from 'pikaday';
    import ExtendedSliderWidget from '../extended/ExtendedDateSliderWidget.js';
    import {getDifferenceBetweenDates, compareDates, getNowDate} from 'utils/CommonUtils.js';
    import {createEventDispatcher, onMount} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    const now = new Date();

    export let sort = { field: 'acqdate', asc: false };
    export let isMouseHovered = false;
    export let isToggledHidden = true;
    export let value = [now, now];
    export let tmpValue = [now, now];
    export let date = [now, now];

    let isSortHidden;
    $: {
        const {field = ''} = sort;        
        if (field === 'acqdate' || isMouseHovered) {
            isSortHidden = false;
        }
        else {
          isSortHidden = true;  
        }
    }

    const datesEqual = (a,b) => {
        return Date.prototype.valueOf.call(a) === Date.prototype.valueOf.call(b);
    };
    let isFilterApplied;
    $: {
        const minDate = date[0] || now;
        const maxDate = date[1] || now;
        const minValue = value[0] || now;
        const maxValue = value[1] || now;        
        isFilterApplied = !compareDates(minDate, minValue) || !compareDates(maxDate, maxValue);
    }
    $: sortMethod = !sort.asc ? 'down' : 'up';
    let datesDiff;
    $: {
        const minValue = value[0] ? value[0] : now;
        const maxValue = value[1] ? value[1] : now;
        datesDiff = getDifferenceBetweenDates(minValue, maxValue);
    }
    $: minDate = date ? date[0] : now;
    $: maxDate = date ? date[1] : now;

    onMount(() => {
        initDatePicker();
        initSlider();
    });

    const getMonthName = month => {
        const monthObject = {
            0: 'янв',
            1: 'фев',
            2: 'мар',
            3: 'апр',
            4: 'май',
            5: 'июн',
            6: 'июл',
            7: 'авг',
            8: 'сен',
            9: 'окт',
            10: 'ноя',
            11: 'дек'
        }
        return monthObject[parseInt(month)];
    }

    let _dateSlider;    
    export const setData = (filterDate, setLimits = true) => {                
        date = filterDate;
        value = filterDate;
        tmpValue = filterDate;
        isToggledHidden = true;

        if (setLimits) {
            _dateSlider.setLimits(filterDate[0].getTime(), filterDate[1].getTime());
        }
        _dateSlider.values = [filterDate[0].getTime(), filterDate[1].getTime()];

        if (setLimits) {
            _dateSlider._bar.querySelector('.slider-widget-range').style.width = '195px';
        }

        _startDate.setDate(new Date(filterDate[0]));
        _endDate.setDate(new Date(filterDate[1]));
    };

    export const clearFilter = filterDate => setData(filterDate);
    const onSortHover = () => isMouseHovered = true;    

    const onSortOut = () => isMouseHovered = false;        

    const onSortClick = () => {        
        const {asc = false} = sort;
        dispatch('setSorted', {field: 'acqdate', asc: !asc});
    };

    const onHeaderClick = () => {
        const state = !isToggledHidden;
        if (!state) {
            setData(value, false);
        }
        isToggledHidden = state;
        dispatch('onHeaderClick', 'data');
    };

    let startDate;
    let endDate;
    let _startDate;
    let _endDate;
    
    const _dateFormat = 'dd.mm.yy';
    const initDatePicker = () => {        
        
        let i18n = {
            previousMonth : 'Предыдущий месяц',
            nextMonth     : 'Следующий месяц',
            months        : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
            weekdays      : ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
            weekdaysShort : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб']
        };
        switch (Translations.language){
            default:
            case 'ru':
                moment.locale('ru');
                break;
            case 'en':
                moment.locale('en');
                i18n = {
                previousMonth : 'Previous Month',
                nextMonth     : 'Next Month',
                months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
                weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
                weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
                };
                break;
        }

        _startDate = new Pikaday ({
            field: startDate,
            format: 'DD.MM.YYYY',
            yearRange: 20,
            i18n: i18n,
            keyboardInput: false,
            blurFieldOnSelect: false,
        });    

        _endDate = new Pikaday ({
            field: endDate,
            format: 'DD.MM.YYYY',
            //yearRange: 10,
            i18n: i18n,
            keyboardInput: false,
            blurFieldOnSelect: false,
        });

        _startDate.setDate(minDate);
        _endDate.setDate(maxDate);
    };

    let sliderContainer;

    const initSlider = () => {

        _dateSlider = new ExtendedSliderWidget(
            sliderContainer,
            {
                mode: 'date',
                min: minDate.getTime(),
                max: maxDate.getTime(),
                startDate: _startDate,
                endDate: _endDate
            }
        );

        const intValues = [minDate.getTime(), maxDate.getTime()];

        _dateSlider.values = intValues;
        _dateSlider._bar.querySelector('.slider-widget-range').style.width = '195px';
        _dateSlider.addEventListener('stop', onSliderStopHandler);
    };

    const onSliderStopHandler = e => {

        const nowTime = now.getTime();
        const {detail = [nowTime, nowTime]} = e;
        const correctData = [new Date(detail[0]), new Date(detail[1])];

        tmpValue = correctData;
    };

    const onApplyClick = () => {
        value = [...tmpValue];
        isToggledHidden = true;
        dispatch('changeClientFilter', {name: 'date', value: tmpValue});
    };

</script>

<td
    on:mouseover="{onSortHover}"
    on:mouseout="{onSortOut}"
    style="padding-left:10px;" class="table-list-col" data-field="acqdate"
>
    <div class="filterable-field-container">
        <div class="on-hover-div">
            <div class="filterable-applied">
                <div style="display: {isFilterApplied ? 'block' : 'none'};">
                    <span class="date-diff">{datesDiff}</span>
                </div>
            </div>
            <span
                on:click="{onHeaderClick}"
                class:applied="{isFilterApplied}"
                class:active="{!isToggledHidden}"
                class="filterable-header-date filterable-header"
            >{translate('results.date')}</span>
            <i
                on:click="{onSortClick}"
                class="table-list-sort table-list-sort-{sortMethod}"
                style="visibility: {isSortHidden ? 'hidden' : 'visible'};"
            />
        </div>
        <div
            style="visibility: {isToggledHidden ? 'hidden' : 'visible'};"
            class="togglable-content-date togglable-content filterable-date-container"
        >
            <div style="text-align: left; padding-top: 10px;">
                <input
                    bind:this="{startDate}"
                    type="text" value=""
                    class="search-options-period-from-value min-input results-filter-date-start-container"
                />
                -
                <input
                    bind:this="{endDate}"
                    type="text" value=""
                    class="search-options-period-to-value  max-input results-filter-date-end-container"
                />
            </div>
            <div class="results-date-slider-container" bind:this="{sliderContainer}"></div>
            <div class="min-value">{getMonthName(minDate.getMonth())} {minDate.getFullYear()}</div>
            <div class="max-value">{getMonthName(maxDate.getMonth())} {maxDate.getFullYear()}</div>
            <div
                on:click="{onApplyClick}"
                class="apply"
                style="margin-top:25px;"
            >{translate('filter.apply')}</div>
        </div>
    </div>
</td>