import { getAuthManager, ResourceServer } from '@scanex/auth';
// import base64 from 'base-64';

export default class AuthManagerService {

    constructor (config) {

        this._application = config.application;

        this._authManager = getAuthManager();

        this._catalogResourceServer = new ResourceServer(this._authManager, {
            id: 'Catalog',
            root: this._getAuthBaseUrl()
        });
    }

    _getAuthBaseUrl() {

        return `${location.protocol}//${location.host}${location.pathname.substr(0, location.pathname.lastIndexOf('/'))}`;
    }

    _status(status) {
        switch (status) {
            case 200:
            case 201:
                return true;            
            default:                
                return false;
        }
    }

    getAuthManager() {

        return this._authManager;
    }

    getCatalogResourceServer() {

        return this._catalogResourceServer;
    }

    createOrder(params = {}) {
        // return this._catalogResourceServer.sendPostRequest('CreateOrder.ashx', params);

        return new Promise((resolve, reject) => {
            let fd = Object.keys (params).reduce((f,k) => {            
                return f.concat(`${k}=${params[k]}`);
            }, []).join('&');
            return fetch('api/OrderRequests', {
                method: 'POST',
                headers: {                    
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                credentials: 'include',
                body: fd
            })
            .then(response => this._status(response.status) && response.text())
            .then(response => {                
                if (response) {
                    resolve({Status: 'ok'});
                }
                else {
                    resolve({Status: 'error'});    
                }                
            })
            .catch(e => {
                console.log(e);
                resolve({Status: 'error'});
            });
        });        
    }

    getUserInfo() {
        return this._authManager.getUserInfo();
    }

    getShapeMetadata(url, params) {
        return this._catalogResourceServer.sendPostRequest(url, params);
    }

    downloadCsvFile(url, params) {
        return this._catalogResourceServer.sendPostRequest(url, params);
    }

    isCustomer() {
        return fetch ('api/Customers/IsCustomer', {method: 'GET', credentials: 'include'}).then(response => response.json());
    }

    getCurrentOrders() {
        return fetch ('api/Orders/Current', {method: 'GET', credentials: 'include'}).then(response => response.json());
    }

    downloadRegion(id) {        
        // return fetch (`api/Orders/Download/${id}`, {method: 'GET', credentials: 'include'}).then(response => response.json());
        window.location = `api/Orders/Download/${id}`;
    }

    getFiles(path) {
        return fetch('Files', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            credentials: 'include',
            body: `path=${path}`
        });
    }

    downloadFiles(files) {       
        this._application.showLoader(true);
        fetch('api/Regions/Download', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            credentials: 'include',
            body: `files=${files.join(',')}`
        })
        .then(response => response.blob())
        .then(blob => {
            this._application.showLoader(false);
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'bundle.zip';
            a.dispatchEvent(new MouseEvent('click'));
        })
        .catch(console.log);
    }
}