<script>
    import * as Translations from '@scanex/translations';
    import { RangeWidget } from '@scanex/slider-widget';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let mode = 'clouds';
    export let value = [0, 100];
    export let limits = {min: 0, max: 100};

    $: containerClass =`search-options-${mode}`;
    $: labelClass = `search-options-${mode}-title`;
    $: inputClass = `search-options-${mode}-value`;
    $: labelValue = translate((mode === 'clouds' ? 'clouds' : 'angle'));

    let sliderContainer;

    const onSliderStopHandler = e => {
        const {detail} = e;
        value = detail;
    };
    let _slider;
    onMount(() => {        
        _slider = new RangeWidget(sliderContainer, limits);
        _slider.values = value;
        _slider.addEventListener('stop', onSliderStopHandler);
    });

    afterUpdate(() => {                
        if (_slider) {
            _slider.values = value;
        }
        dispatch('changeValues', {value});        
    });

</script>

<div class="{containerClass}">
    <div class="{labelClass}">{labelValue}</div>
    <div bind:this="{sliderContainer}" class="{inputClass}" />
</div>