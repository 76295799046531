<script>
    import * as Translations from '@scanex/translations';
    import {getTotalHeight, createDefaultCriteria} from 'utils/CommonUtils.js';
    import DateWidget from './DateWidget.svelte';
    import SliderWidget from './SliderWidget.svelte';
    import SatellitesWidget from './SatellitesWidget.svelte';
    import ArchiveWidget from './ArchiveWidget.svelte';
    import AnnuallyWidget from './AnnuallyWidget.svelte';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    const fieldList = ['date', 'annually', 'clouds', 'angle', 'resolution', 'satellites', 'stereo', 'archive'];

    let defaultCriteria = createDefaultCriteria();
    export let date = defaultCriteria.date;
    export let annually = defaultCriteria.annually;
    export let clouds = defaultCriteria.clouds;
    export let angle = defaultCriteria.angle;
    export let resolution = defaultCriteria.resolution;
    export let satellites = defaultCriteria.satellites;
    export let stereo = defaultCriteria.stereo;
    export let archive = defaultCriteria.archive;
    export let restricted = false;
    export let cloudsLimits = {min: 0, max: 100};
    export let angleLimits = {min: 0, max: 80};

    afterUpdate(() => {
        const currentCriteria = getCriteria();
        dispatch('change', currentCriteria);
    });

    $: isSomeSatellitesChecked = satellites.ms.some(x => x.checked) || satellites.pc.some(x => x.checked);

    const getCriteria = () => {        
        return {
            date, annually, clouds, angle, resolution, satellites, stereo, archive
        };
    };

    const onSearchClickHandler = () => dispatch('search');

    let searchPane;
    export const getSearchPaneRef = () => searchPane;
    export const resize = () => {
        const { height } = document.body.querySelector('.scanex-sidebar').getBoundingClientRect();
        const total = height - getTotalHeight(['.search-pane', '.search-options-footer' ]) - 11;
        const ctnrHeight = document.body.querySelector('#map .scanex-sidebar .panels .search-options-content .search-options-fixed-section').getBoundingClientRect().height;
        const st = document.body.querySelector('#map .scanex-sidebar .panels .search-options-content .search-options-satellites');

        st.style.maxHeight = `${total - ctnrHeight - 33 + 15}px`;
        st.style.height = st.style.maxHeight;
    };

    const onChangeSatellites = ({detail}) => {
        satellites = detail._satellites;
    };

</script>

<div bind:this="{searchPane}" class="search-pane" />
        
<div class="no-select search-options-pane search-options search-options-content">
    <div class="no-select search-options-fixed-section">
        <div class="search-options-period-section" style="margin-top:0;">
            <div class="search-options-period">
                <DateWidget
                    mode="from"
                    date="{date[0]}"
                    on:setDate="{({detail}) => date[0] = detail.date}"
                />
                <DateWidget
                    mode="to"
                    date="{date[1]}"
                    on:setDate="{({detail}) => date[1] = detail.date}"
                />
            </div>
            
            <AnnuallyWidget
                annually="{annually}"
                on:setAnnually="{({detail}) => annually = detail.annually}"
            />

        </div>

        <SliderWidget
            mode="clouds"
            value="{clouds}"
            limits="{cloudsLimits}"
            on:changeValues="{({detail}) => clouds = detail.value}"
        />
        <SliderWidget
            mode="angle"
            value="{angle}"
            limits="{angleLimits}"
            on:changeValues="{({detail}) => angle = detail.value}"
        />

        <ArchiveWidget
            restricted="{restricted}"
            archive="{archive}"
            on:setArchive="{({detail}) => archive = detail.archive}"
        />
    </div>

    <SatellitesWidget
        _satellites="{satellites}"
        restricted="{restricted}"
        on:changeSatellites="{onChangeSatellites}"
    />

</div>

<div class="search-options-footer">
    <button
        type="button"
        on:click="{onSearchClickHandler}"
        class="search-options-search-button search-options-search-button-{isSomeSatellitesChecked ? 'active':'passive'}"
    >
        <span>{translate('general.search')}</span>
    </button>
</div>