import * as Translations from '@scanex/translations';
import {
    DEFAULT_LANGUAGE,
    LOCAL_STORAGE_KEY,
    ACCESS_LAYER_ID,
    ACCESS_USER_ROLE,
    VERSION_PATH
} from 'config/constants/Constants.js';
import { isMobile } from 'utils/CommonUtils.js';
import RequestManager from './requestManager/RequestManager.js';
import DataStore from './searchDataStore/SearchDataStore.js';
import MapComponent from './map/Map.js';
import ModalComponent from './modal/ModalComponent.js';
import Events from './events/Events.js';

class Application {

    constructor(config) {

        this._config = config;

        this._appEvents = new Events();

        this._serviceEvents = new Events();
    }

    async start() {

        this._initRequestManager();

        this._initStore();

        this._setLocale();

        await this._loadCommonData();



        await this._initMap();

        this._initBridgeControllers();

        this._initMapManagers();

        this._initAddons();

        this._initDialogComponent();

        this._initUiElements();

        this._bindEvents();

        this._applyAddonsAfterIniting();
    }

    _bindEvents() {

        const events = this.getAppEvents();

        window.addEventListener('resize', () => events.trigger('system:window:resize'));
    }

    _initRequestManager() {

        this._requestManager = new RequestManager({
            application: this
        });
    }

    _initStore() {

        const {store} = this._config;

        const dataStore = new DataStore(store);
        this._dataStore = dataStore;
        window.CatalogStore = dataStore;
    }

    _setLocale() {

        const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);  
        const viewState = JSON.parse (storedState) || {};
        let lang = DEFAULT_LANGUAGE;
        window.language = 'rus';
        if (viewState) {
            switch (viewState.lang) {
                case 'rus':
                case 'ru':
                    lang = 'ru';
                    window.language = 'rus';
                    break;
                case 'eng':
                case 'en':
                    lang = 'en';
                    window.language = 'eng';
                    break;
                default:
                    break;
            }
        }

        Translations.setLanguage(lang);
        L.gmxLocale.setLanguage(lang);
    }

    async _loadCommonData() {

        await this._getUserInfo();

        //await this._checkAccess(); // Really Need it ???

        await this._loadVersion();
    }
    

    async _getUserInfo() {

        const requestManager = this.getRequestManager();

        let userInfo = {};
        try {
            const response = await requestManager.requestGetUserInfo();
            const { ID, FullName, Email, Phone, Organization, Role } = response.Result;
            //console.log('requestGetUserInfo', response.Result); // для проверки роли (ее не было ранее, но как оказалось логика вообще не завязана на ене)                         
            userInfo = {                
                IsAuthenticated: true,
                IsCustomer: false,
                // ID: ID,
                FullName: FullName,
                Email: Email,
                Phone: Phone,
                Organization: Organization,
                Role: Role // Add new in april 2021
            };
            try {            
                userInfo.IsCustomer = await requestManager.isCustomer(); // Info from Orders DB
            }
            catch(e) {
                console.log('userIsCustomerExcept', e);            
            }        
        }
        catch(e) {
            console.log('userInfoExcept', e);
            userInfo = { IsAuthenticated: false };
        }        

        const store = this.getStore();
        store.rewriteData('userInfo', userInfo);
    }

    async _checkAccess() {

        const store = this.getStore();
        const userInfo = store.getData('userInfo');

        const requestManager = this.getRequestManager();
        //console.log('getRequestManager - Success!');

        try {
            //console.log('layerId', ACCESS_LAYER_ID);
            const response = await requestManager.requestGetLayerId({
                layerID: ACCESS_LAYER_ID
            }); // Тут все и останавливается
            //console.log('GetLayerId - Success!');
            const {Status, Result = {}} = response;

            const LayerID = Result === null ? null : Result['LayerID'];
            
            // В апреле статус перестал возвращаться. Шумилов после что-то поправил и стало возвращаться только при первом логировании, что неверно.
            // В итоге внутри условия принудительно выставляется роль scanex. Хотя Роли были предусмотрены в my.*.ru
            // С Ирой решили временно переделать на проверку роли - части пользователей выставили роль scanex.
            // Решено было вернуть роли на место и делать проверку по почте Сканекса, пока не исправят my.
            if (Status === 'ok' && Result && LayerID === ACCESS_LAYER_ID) {
                userInfo['Role'] = ACCESS_USER_ROLE; // bad logic
                store.rewriteData('userInfo', userInfo);
            }
        }
        catch(e) {
            console.log('GetLayerId - BAD!'); // test
            console.log('checkAccessExcept', e);
        }
    }

    async _loadVersion() {

        const language = Translations.language;
        const versionPath = VERSION_PATH + language + '.txt';

        const response = await fetch(versionPath);

        const text = await response.text();
        
        const store = this.getStore();
        store.setMetaItem('about', text);
    }

    async _initMap() {

        const mapComponent = new MapComponent({
            application: this
        });
        await mapComponent.loadMap();

        this._mapComponent = mapComponent;
    }

    _initBridgeControllers() {

        const {bridgeControllers = []} = this._config;

        this._bridgeControllers = {};

        for (let i = 0; i < bridgeControllers.length; i++ ) {

            const currentController = bridgeControllers[i];
            const {index, constructor} = currentController;

            this._bridgeControllers[index] = new constructor({
                map: this.getMap(),
                application: this
            });
        }
    }

    _initMapManagers() {

        const {mapManagers = []} = this._config;

        this._mapManagers = {};

        for (let i = 0; i < mapManagers.length; i++ ) {

            const currentManager = mapManagers[i];
            const {index, constructor} = currentManager;

            this._mapManagers[index] = new constructor({
                map: this.getMap(),
                application: this,
                store: this._dataStore
            });
        }
    }

    _initAddons() {

        const appEvents = this.getAppEvents();
        const {addons = []} = this._config;

        this._addons = {};

        for (let i = 0; i < addons.length; i++ ) {

            const currentAddon = addons[i];
            const {index, constructor} = currentAddon;

            this._addons[index] = new constructor({
                name: index,
                application: this
            });

            appEvents.trigger(`system:addon:${index}:created`);
        }
    }

    _initDialogComponent() {

        const modalComponent = new ModalComponent({
            application: this,
            map: this.getMap()
        });

        modalComponent.init();

        this._modalComponent = modalComponent;
    }

    _initUiElements() {

        const isMobileGadget = isMobile();
        const {uiElements = []} = this._config;
        const appEvents = this.getAppEvents();

        this._uiElements = {};

        for (let i = 0; i < uiElements.length; i++ ) {

            const currentElement = uiElements[i];
            const {index, constructor, mobile = true} = currentElement;

            if ( (isMobileGadget && mobile) || !isMobileGadget) {

                this._uiElements[index] = new constructor({
                    name: index,
                    application: this,
                    map: this.getMap()
                });
    
                this._uiElements[index].init();

                appEvents.trigger(`system:ui:${index}:created`);
            }
        }

        appEvents.trigger('system:uiElements:created');
    }

    _applyAddonsAfterIniting() {

        const {_addons} = this;

        for (let addonKey in _addons) {
            const addon = _addons[addonKey];
            addon['globalApply'] && addon.globalApply();
        }
    }

    _errorHandle(e) {

        window.console.error(e);
    }

    showLoader(state = false) {
        const loaderWidget = this.getUiElement('loadingIndicator');
        loaderWidget.show(state);
    }

    showNotification(message = '') {

        const notificationWidget = this.getUiElement('popupNotificator');

        notificationWidget.show(message);
    }

    showError(errorText = '', errorHeader = '') {

        const modalComponent = this.getModal();

        modalComponent.show({
            component: 'error',
            headerText: errorHeader,
            messageText: errorText
        });
    }

    getRequestManager() {

        return this._requestManager;
    }

    getBridgeController(name) {

        return this._bridgeControllers[name];
    }

    getMapManager(name) {

        return this._mapManagers[name];
    }

    getAddon(index) {

        return this._addons[index];
    }

    getModal() {

        return this._modalComponent;
    }

    getUiElement(index) {

        return this._uiElements[index];
    }

    getStore() {

        return this._dataStore;
    }

    getMap() {

        return this._mapComponent.getMap();
    }

    getMapContainer() {

        return this._mapComponent.getMapContainer()
    }

    getMapComponent() {

        return this._mapComponent;
    }

    getAppEvents() {

        return this._appEvents;
    }

    getServiceEvents() {

        return this._serviceEvents;
    }

}

export default Application;