<script>

    import * as Translations from '@scanex/translations';
    import {createEventDispatcher, onMount} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    const archiveValues = ['global', 'local', 'all'];
    export let restricted = false;
    export let archive = 'global';

    const onArchiveChangeHandler = e => {
        let {target: {value = 'global'}} = e;
        if (archiveValues.indexOf(value) === -1) {
            value = 'global';
        }
        dispatch('setArchive', {archive: value});
    }
</script>

<div class="search-options-satellites-number-section" style="margin-bottom: 0; padding-bottom: 15px;">
    <div class="search-options-satellites-title">{translate('general.satellites')}</div>
    <div class="search-options-satellites-number"></div>
    {#if restricted}
        <div class="search-options-satellites-archive">
            <select
                value="{archive}"
                on:blur="{onArchiveChangeHandler}"
                style="width:115px;"
            >
                <option value="global">{translate('archive.global')}</option>
                <option value="local">{translate('archive.local')}</option>
                <option value="all">{translate('archive.all')}</option>
            </select>
        </div>
    {/if}
</div>