import BaseComponent from 'base/BaseComponent.js';
import View from './view/View.svelte';
import { getProperty } from 'application/searchDataStore/SearchDataStore.js';

export default class TableHeaderComponent extends BaseComponent {

    init() {
        this._container = document.querySelector('#map .leaflet-control.scanex-sidebar .panels .results-pane');
        this._view = new View({target: this._container});
        this._bindEvents();
    }

    getContainer() {
        return this._container;
    }

    _bindEvents() {

        const application = this.getApplication();
        const store = application.getStore();
        const view = this.getView();
        const ContourController = application.getBridgeController('contour');

        store.on('contours:researchedListHeader', this._onSetSatellites.bind(this));
        store.on('contours:startResearchedListHeader', this._onSetSatellites.bind(this));
        store.on('clientFilter:clear', this._onClearFilter.bind(this));
        view.$on('setSorted', this._onSetSorted.bind(this));
        view.$on('changeClientFilter', ({detail}) => this.events.trigger('filter:change', detail));
        view.$on('addAllToCart', () => ContourController.addAllToCartOnListAndMap());
    }

    _onSetSorted({detail}) {        
        this.events.trigger('sortGrid', detail);
    }

    _onClearFilter() {

        const application = this.getApplication();
        const store = application.getStore();
        const view = this.getView();

        const {clouds, angle, date} = store.getData('searchCriteria');

        const clearedFilter = {
            isChanged: false,
            filterData: {
                unChecked: [],
                clouds,
                angle,
                date
            }
        };

        view.clearFilter({ clientFilter: clearedFilter });
    }

    _onSetSatellites() {

        const application = this.getApplication();
        const store = application.getStore();
        const view = this.getView();

        const results = store.getResults();
        const {satellites, clouds, angle, date} = store.getData('searchCriteria');

        const clearedFilter = {
            isChanged: false,
            filterData: {
                unChecked: [],
                clouds,
                angle,
                date
            }
        };

        let resultPlatforms = [];
        results.forEach(item => {
            const platform = getProperty(item, 'platform');
            if (resultPlatforms.indexOf(platform) === -1) {
                resultPlatforms.push(platform);
            }
        });

        let correctSatellites = [];
        satellites.ms.forEach(item => {
            //console.log(item.name, item.platforms)
            const hasInResults = item.platforms.some(item => resultPlatforms.indexOf(item) !== -1);
            item.checked && hasInResults && correctSatellites.push(item);
        });
        satellites.pc.forEach(item => {
            //console.log(item.name, item.platforms)
            const hasInResults = item.platforms.some(item => resultPlatforms.indexOf(item) !== -1);
            item.checked && hasInResults && correctSatellites.push(item);
        });

        view.setData({
            satellites: correctSatellites,
            clientFilter: clearedFilter
        });
    }

}