<script>
    import * as Translations from '@scanex/translations';
    import Satellites from './Satellites.svelte';
    import Cloudness from './Cloudness.svelte';
    import Angles from './Angles.svelte';
    import Dates from './Dates.svelte';
    import {createEventDispatcher, onMount} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    const now = new Date();

    export let clientFilter = {
        unChecked: [],
        clouds: [0, 100],
        angle: [0, 80],
        date: [now, now]
    };
    export let sort = { field: 'acqdate', asc: true };

    const onCancelClick = () => hidden = true;

    let textInput;
    const onCopyClick = () => {        
        dispatch('copy', textInput);
        hidden = true;
    };

    const changeSorting = ({detail}) => {
        sort = detail;
        dispatch('setSorted', sort);
    };

    const changeClientFilter = ({detail}) => {        
        dispatch('changeClientFilter', detail);
    };

    let stereo
    let satellites;
    let cloudness;
    let angles;
    let dates;

    export const setData = data => {

        const {clientFilter: {filterData: {stereo=[], clouds = [], angle = [], date: filterDate = []}}} = data;

        //stereo.$set({stereo: data.stereo, isToggledHidden: true});
        //stereo.$set({stereo, value: stereo, tmpValue: stereo, isToggledHidden: true});
        satellites.$set({satellites: data.satellites, isToggledHidden: true});
        cloudness.$set({clouds, value: clouds, tmpValue: clouds, isToggledHidden: true});
        angles.$set({angle, value: angle, tmpValue: angle, isToggledHidden: true});
        dates.setData(filterDate);

    };

    export const clearFilter = filterData => {
        const {clientFilter: {filterData: {unChecked = [], stereo = [], clouds = [], angle = [], date = []}}} = filterData;
        satellites.$set({
            unChecked,
            tmpUnchecked: unChecked,
            isToggledHidden: true
        });
        //stereo.clearFilter(stereo);
        cloudness.clearFilter(clouds);
        angles.clearFilter(angle);
        dates.clearFilter(date);
    };

    const onHeaderClick = ({detail}) => {
        const state = detail;
        if (state === 'satellites') {
            //stereo.$set({isToggledHidden: true});
            cloudness.$set({isToggledHidden: true});
            angles.$set({isToggledHidden: true});
            dates.$set({isToggledHidden: true});
        }
        else if (state === 'clouds') {
            //stereo.$set({isToggledHidden: true});
            satellites.$set({isToggledHidden: true});
            angles.$set({isToggledHidden: true});
            dates.$set({isToggledHidden: true});
        }
        else if (state === 'angles') {
            //stereo.$set({isToggledHidden: true});
            satellites.$set({isToggledHidden: true});
            cloudness.$set({isToggledHidden: true});
            dates.$set({isToggledHidden: true});
        }
        else {
            //stereo.$set({isToggledHidden: true});
            satellites.$set({isToggledHidden: true});
            cloudness.$set({isToggledHidden: true});
            angles.$set({isToggledHidden: true});
        }
    };

    const onAddAllToCartClick = () => {
        dispatch('addAllToCart');
    };

    const onSortClick = () => {    
        const {asc = false} = sort;
        dispatch('setSorted', {field: 'stereo', asc: !asc});
    };

    $: sortMethod = !sort.asc ? 'down' : 'up';

</script>

<div class="table-list-header">
    <table>
        <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr>
                <td class="table-list-col" data-field="visible" style="padding-top:20px;">
                    <i class="table-list-sort" style="display: none"></i>
                </td>
                <td title="Стерео" class="table-list-col" data-field="stereo" style="padding-top:20px;padding-left:0px;">
                    <i
                    on:click="{onSortClick}"
                     class="search search-stereo table-list-sort-{sortMethod}">
                     </i>
                    <i
					class="table-list-sort"
					i>
                </td>
                
                <Satellites
                    bind:this="{satellites}"
                    on:setSorted="{changeSorting}"
                    on:changeClientFilter="{changeClientFilter}"
                    on:onHeaderClick="{onHeaderClick}"
                    sort="{sort}"
                    unChecked="{clientFilter['unChecked']}"
                    tmpUnchecked="{clientFilter['unChecked']}"
                />

                <Cloudness
                    bind:this="{cloudness}"
                    clouds="{clientFilter['clouds']}"
                    on:setSorted="{changeSorting}"
                    on:onHeaderClick="{onHeaderClick}"
                    sort="{sort}"
                    on:changeClientFilter="{changeClientFilter}"
                />

                <Angles
                    bind:this="{angles}"
                    angle="{clientFilter['angle']}"
                    on:setSorted="{changeSorting}"
                    on:onHeaderClick="{onHeaderClick}"
                    sort="{sort}"
                    on:changeClientFilter="{changeClientFilter}"
                />

                <Dates
                    bind:this="{dates}"
                    date="{clientFilter['date']}"
                    on:setSorted="{changeSorting}"
                    on:onHeaderClick="{onHeaderClick}"
                    sort="{sort}"
                    on:changeClientFilter="{changeClientFilter}"
                />
                
                <td class="table-list-col" data-field="info" style="padding-top:20px;">
                    <i class="table-list-sort" style="display: none"></i>
                </td>
                <td
                    title="{translate('results.cart')}"
                    class="table-list-col"
                    data-field="cart"
                    style="padding-top:20px;"
                    on:click="{onAddAllToCartClick}">
                    <i class="cart-add cart-add-active"></i>
                    <i class="table-list-sort" style="display: none"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>