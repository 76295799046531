// import "@babel/polyfill";
// import 'nodelist-foreach-polyfill';

import './services/css/Css.js';
import './services/translations/Translations.js';

import { createDefaultCriteria, createDefaultFilter } from './utils/CommonUtils.js';

import Application from './application/Application.js';

import DrawingBridgeController from './components/bridgeControllers/DrawingBridgeController.js';
import ContourBridgeController from './components/bridgeControllers/ContourBridgeController.js';

import DrawingMapManager from './components/mapManagers/DrawingsMapManager.js';
import ContourMapManager from './components/mapManagers/ContoursMapManager.js';

import ShapeLoaderAddon from './components/addons/shapeLoader/ShapeLoaderAddon.js';
import AppStateManagerAddon from './components/addons/appStateManager/AppStateManagerAddon.js';

import LoadingIndicatorUiElement from './components/uiElements/customElements/loadingIndicator/LoadingIndicatorUiElement.js';
import PopupNotificationUiElement from './components/uiElements/customElements/popupNotification/PopupNotificationUiElement.js';
import HelpButtonUiElement from './components/uiElements/customElements/helpButton/HelpButtonUiElement.js';
import AuthInformationUiElement from './components/uiElements/customElements/authInformation/AuthInformationUiElement.js';
import LanguageSelectUiElement from './components/uiElements/customElements/languageSelect/LanguageSelectUiElement.js';

import PointUiElement from './components/uiElements/iconElements/point/PointUiElement.js';
import PolylineUiElement from './components/uiElements/iconElements/polyline/PolylineUiElement.js';
import PolygonUiElement from './components/uiElements/iconElements/polygon/PolygonUiElement.js';
import RectangleUiElement from './components/uiElements/iconElements/rectangle/RectangleUiElement.js';
import BoxZoomUiElement from './components/uiElements/iconElements/boxZoom/BoxZoomUiElement.js';
import UploadUiElement from './components/uiElements/iconElements/upload/UploadUiElement.js';
import DownloadUiElement from './components/uiElements/iconElements/download/DownloadUiElement.js';
import PermalinkUiElement from './components/uiElements/iconElements/permalink/PermalinkUIElement.js';

import BaseLayersUiElement from './components/uiElements/widgetElements/baseLayers/BaseLayersUiElement.js';
import ZoomUiElement from './components/uiElements/widgetElements/zoom/ZoomUiElement.js';
import SidebarUiElement from './components/uiElements/widgetElements/sidebar/SidebarUiElement.js';
import DrawingObjectsUiElement from './components/uiElements/widgetElements/drawingObjects/DrawingObjectsUiElement.js';
import AdvertisementUiElement from './components/uiElements/widgetElements/advertisement/AdvertisementUiElement.js';


const application = new Application({

    //store
    store: {
        'name': 'searchStore',
        'data': [
            {
                'key': 'userInfo',
                'isTable': false
            },
            {
                'key': 'drawings',
                'isTable': true,
                'indexBy': 'id'
            },
            {
                'key': 'regions',
                'isTable': true,
                'indexBy': 'id'
            },
            {
                'key': 'contours',
                'isTable': true,
                'indexBy': 'gmx_id'
            },
            {
                'key': 'orders',
                'isTable': true,
                'indexBy': 'gmx_id'
            },
            {
                'key': 'downloadCache',
                'isTable': false
            },
            {
                'key': 'searchCriteria',
                'isTable': false,
                'defaultValue': createDefaultCriteria()
            },
            {
                'key': 'clientFilter',
                'isTable': false,
                'defaultValue': {
                    isChanged: false,
                    filterData: createDefaultFilter()
                }
            },
            {
                'key': 'meta',
                'isTable': false,
                'defaultValue': {
                    currentTab: null,
                    about: '',
                    cancelLoading: false,
                    updateResults: false,
                    activeIcon: null,
                    activeLayer: null
                }
            }
        ]
    },

    // bridge controllers
    bridgeControllers: [
        {
            'index': 'drawing',
            constructor: DrawingBridgeController
        },
        {
            'index': 'contour',
            constructor: ContourBridgeController
        }
    ],

    // map managers
    mapManagers: [
        {
            'index': 'drawing',
            constructor: DrawingMapManager
        },
        {
            'index': 'contour',
            constructor: ContourMapManager
        }
    ],

    // addons
    addons: [
        {
            index: 'shapeLoader',
            constructor: ShapeLoaderAddon
        },
        {
            index: 'appStateManager',
            constructor: AppStateManagerAddon
        }
    ],

    // components
    uiElements: [
        {
            type: 'custom',
            index: 'loadingIndicator',
            constructor: LoadingIndicatorUiElement
        },
        {
            type: 'custom',
            index: 'popupNotificator',
            constructor: PopupNotificationUiElement
        },
        {
            type: 'custom',
            index: 'helpButton',
            constructor: HelpButtonUiElement
        },
        {
            type: 'custom',
            index: 'authInformation',
            constructor: AuthInformationUiElement
        },
        {
            type: 'custom',
            index: 'languageSelect',
            constructor: LanguageSelectUiElement
        },
        {
            type: 'icon',
            index: 'point',
            constructor: PointUiElement
        },
        {
            type: 'icon',
            index: 'polyline',
            constructor: PolylineUiElement,
            mobile: false
        },
        {
            type: 'icon',
            index: 'polygon',
            constructor: PolygonUiElement,
            mobile: false
        },
        {
            type: 'icon',
            index: 'rectangle',
            constructor: RectangleUiElement,
            mobile: false
        },
        {
            type: 'icon',
            index: 'permalink',
            constructor: PermalinkUiElement
        },
        {
            type: 'icon',
            index: 'boxZoom',
            constructor: BoxZoomUiElement,
            mobile: false
        },
        {
            type: 'icon',
            index: 'upload',
            constructor: UploadUiElement
        },
        {
            type: 'icon',
            index: 'download',
            constructor: DownloadUiElement
        },
        {
            type: 'widget',
            index: 'baseLayers',
            constructor: BaseLayersUiElement
        },
        {
            type: 'widget',
            index: 'zoom',
            constructor: ZoomUiElement
        },
        {
            type: 'widget',
            index: 'drawingObjects',
            constructor: DrawingObjectsUiElement
        },
        {
            type: 'widget',
            index: 'advertisement',
            constructor: AdvertisementUiElement
        },
        {
            type: 'widget',
            index: 'sidebar',
            constructor: SidebarUiElement
        }
    ]

});

application.start();