import BaseCompositedComponent from 'base/BaseCompositedComponent.js';
import OrderView from '@scanex/order-viewer';
import * as Translations from '@scanex/translations';
import {LAYER_ID} from 'config/constants/Constants.js';
import {
    getProperty,
    setProperty,
} from 'application/searchDataStore/SearchDataStore.js';
import {getBounds} from 'utils/CommonUtils.js';

export default class OrdersTabComponent extends BaseCompositedComponent {

    init() {
        const options = {
            id: 'orders',            
            icon: 'sidebar-orders',
            opened: 'sidebar-orders-opened',
            closed: 'sidebar-orders-closed',
            tooltip: Translations.translate('results.orders')
        };
        this._view = this.getParentComponent().getView().addTab('orders');        
        this._bindEvents();
    }

    _bindEvents() {
        const application = this.getApplication();
        const store = application.getStore();
        application.getServiceEvents().on('sidebar:tab:resize', height => {            
            let placeHolder = this.getView();
            placeHolder.style.maxHeight = `${height}px`;
        });   
        
        const collect_geometries = (rm, orders) => new Promise((resolve, reject) => {
            const sceneids = orders.reduce((a, {regions}) => {
                const x = regions.reduce((b, {granules}) => {
                    const y = granules.reduce((c, {granule: {sceneId}, granuleId}) => {
                        c[sceneId] = granuleId;
                        return c;
                    }, {});
                    return {...b, ...y};
                }, {});
                return {...a, ...x};
            }, {});

            const ids = Object.keys(sceneids);

            if (ids.length) {
                let query = ids.map(x => "(sceneid = '" + x + "')").join(' OR ');
                let rq = {
                    layer: LAYER_ID,                
                    geometry: true,
                    pagesize: 0,
                    query: query,
                    out_cs: 'EPSG:3857',
                };        
    
                rm.requestVectorLayerSearch(rq)
                .then(response => {
                    if (response.Status == 'ok') { 
                        let {fields, values} = response.Result;
                        const gmxIdIndex = fields.indexOf('gmx_id');
                        const sceneIdIndex = fields.indexOf('sceneid');
                        const granules = values.reduce((a, item) => {
                            const gmxId = item[gmxIdIndex];
                            const sceneId = item[sceneIdIndex];                        
                            a[gmxId] = sceneids[sceneId];
                            return a;
                        }, {});                    
                        resolve({result: response.Result, granulesByGmxId: granules});
                    }
                    else {
                        reject(response.Result);
                    }
                })
                .catch(err => {                
                    reject(err);
                });
            }
            else {
                resolve({result: null, granulesByGmxId: {}});
            }            
        });

        application.getAppEvents().on('system:uiElements:created', () => {
            //console.log('start');            
            const rm = application.getRequestManager();
            rm.getCurrentOrders().then (orders => {
                collect_geometries(rm, orders)
                .then(({result, granulesByGmxId}) => {
                    const contourController = application.getBridgeController('contour');
                    const drawingController = application.getBridgeController('drawing');
                    if (result) {
                        contourController.addContoursToMapOrders(result);
                    }                    
                    this._orderView = new OrderView({ target: this.getView(), props: {orders} });
                    this._orderView.$on('download', ({detail}) => {                                                
                        rm.downloadFiles(detail);
                    });
                    let regions = {};
                    this._orderView.$on('preview', ({detail}) => {
                        const {id, visible, granules, geoJSON} = detail;
                        if (geoJSON) {
                            const {bbox: [xmin, ymin, xmax, ymax], features} = JSON.parse(geoJSON);
                            if (regions[id]) {
                                const drawingIds = regions[id];
                                if (Array.isArray(drawingIds)) {
                                    drawingIds.forEach(drawingId => drawingController.toggleRegion(drawingId, visible)); 
                                }                                
                            }
                            else {                                
                                regions[id] = drawingController.addDrawings(features);
                            }
                        }
                        
                        const contours = store.getData('orders');                        
                        const resultsForAdding = Object.keys(contours)
                            .map(gmxId => {
                                let item = contours[gmxId];
                                const granuleId = granulesByGmxId[gmxId];
                                if (granules[granuleId]) {
                                    item = setProperty(item, {'visible': visible ? 'visible' : 'hidden'});
                                }
                                return {
                                    id: gmxId,
                                    content: item
                                };
                            });
                        if (visible) {
                            const items = Object.keys(contours)
                                .filter(gmxId => {
                                    const item = contours[gmxId];
                                    const granuleId = granulesByGmxId[gmxId];
                                    const visibleState = getProperty(item, 'visible');
                                    return visibleState === 'visible' && granules[granuleId];
                                })
                                .map(gmxId => {
                                    const {properties} = contours[gmxId];
                                    return properties;
                                });
                            const bounds = getBounds(items);
                            this._map.fitBounds(bounds, { animate: false });                            
                        }                         
                        store.rewriteData('orders', resultsForAdding, ['orders:visibilityChanged']);
                    });
                    this._orderView.$on('expand', ({detail}) => {
                        const {expand, filePath} = detail;
                        rm.getFiles(filePath)
                        .then(res => res.json())
                        .then(res => expand(res))
                        .catch(e => console.log(e));
                    });
                }).catch(console.log);
            }).catch (console.log);
        });
        //console.log('end');  
        store.on('currentTab:changeUI', () => {
            const currentTab = store.getMetaItem('currentTab');
            if (!currentTab && this._orderView) {
                this._orderView.resetVisibility();
            }
        });
    }   
}