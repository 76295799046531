import { ACCESS_USER_ROLE } from 'config/constants/Constants.js';
import { stRange, toQuery, getBbox } from 'utils/CommonUtils.js';
import * as Translations from '@scanex/translations';

Translations.add('ru', {
    tooManyGeometries: 'Количество контуров превышает лимит'
});
Translations.add('en', {
    tooManyGeometries: 'Too many search geometries in query'
});

const SEARCH_GEOMETRIES_LIMIT = 100000;

export default class AdapterCore {

    constructor({layer, gmxResourceServer, application}){

        this._layer = layer;
        this._gmxResourceServer = gmxResourceServer;
        this._geometries = [];

        this._application = application;
    }

    _getAuthorized() {

        const store = this._application.getStore();
        const userInfo = store.getData('userInfo');

        return userInfo['IsAuthenticated'] && (userInfo['Role'] === ACCESS_USER_ROLE || userInfo['Role'] === "admin");
    }

    set criteria (value) {
        this._criteria = value;
    }

    get criteria () {
        return this._criteria;
    }

    get satellites () {

        let {satellites, archive} = this._criteria;

        let serialize = s => Object.keys(s).reduce((a,k) => a.concat(s[k]), []);
        let ss = serialize(satellites.ms).concat(serialize(satellites.pc));

        return `(${ss.filter(x => x.checked).map(x => `(${x.condition(archive, this._getAuthorized())})`).join(' OR ')})`;
        /*const serialize = s => Object.keys(s).reduce((a,k) => a.concat(s[k]), []);
        const satellitesIds = ['WV04', 'WV03', 'WV02', 'WV01', 'GE01', 'IK', 'QB02'];
        let {satellites, archive} = this._criteria;

        let ms = satellites.ms;
        let pc = satellites.pc;*/
        /*if (this._getAuthorized()) {
            ms = satellites.ms.filter(item => satellitesIds.indexOf(item.id) === -1);
            pc = satellites.pc.filter(item => satellitesIds.indexOf(item.id) === -1);
        }*/

        /*let ss = serialize(ms).concat(serialize(pc));
        let platformsList = ss.filter(x => x.checked).map(x => {
            return `(${x.condition(archive, this._getAuthorized())})`
        });
        let newPlatforms = [];
        platformsList.forEach(item => {
            if (item !== '()') {
                newPlatforms.push(item);
            }
        });
        if (newPlatforms.length < 1) {
            newPlatforms.push('platform IN (\'_NO_\') AND islocal = FALSE');
        }
        return `(${newPlatforms.join(' OR ')})`;*/
    }

    get geometries () {
        return this._geometries;
    }

    set geometries (value) {
        this._geometries = value;
    }

    get geometry () {        
        return {
            type: 'GeometryCollection',
            geometries: this._geometries,
        };
    }

    get hasGeometry() {
        return Array.isArray (this._geometries) && this._geometries.length > 0;
    }

    _formatDate (date) {                
        return moment(date).format('YYYY-MM-DD') ;
    }

    get date () {
        let {date, annually} = this._criteria;
        let [dateStart, dateEnd] = date;
        if (annually) {
            let dcr = [];
            let startMonth = dateStart.getMonth();
            let startDay = dateStart.getDate();
            let endMonth = dateEnd.getMonth();
            let endDay = dateEnd.getDate();
            let endYear = dateEnd.getFullYear();
            if (startMonth > endMonth) {
                --endYear;
            }
            for (let year = dateStart.getFullYear(); year <= endYear; year++) {
                let start = new Date(year, startMonth, startDay);
                let end = new Date(startMonth > endMonth ? year + 1 : year, endMonth, endDay);
                dcr.push(`(acqdate >= '${this._formatDate(start)}' AND acqdate <= '${this._formatDate(end)}')`);
            }
            return `(${dcr.join(' OR ')})`;
        }
        else {
            return `(acqdate >= '${this._formatDate (dateStart)}' AND acqdate <= '${this._formatDate(dateEnd)}')`;
        }
    }

    get clouds () {
        let { clouds } = this._criteria;
        let [min, max] = clouds;
        return `(cloudness IS NULL OR cloudness < 0 OR (cloudness >= ${min.toFixed(1)} AND cloudness <= ${max.toFixed(1)}))`;
    }

    get angle() {
        let { angle } = this._criteria;
        let [min, max] = angle;
        return `(tilt IS NULL OR tilt < 0 OR (tilt >= ${min.toFixed(1)} AND tilt <= ${max.toFixed(1)}))`;
    }

    get stereo () {
        let { stereo } = this._criteria;
        return stereo ? "NOT (stereo IS NULL OR stereo = 'NONE')" : '';
    }

    get spatial () {        
        return `Intersects([geomixergeojson], buffer(GeometryFromGeoJson('${JSON.stringify(this.geometry)}', 4326), 0.001))`
    }

    get archive () {
        let { archive } = this._criteria;
        switch(archive){ 
            case 'global':
                return 'islocal = FALSE';
            case 'local':
                return 'islocal = TRUE';
            default:
            case 'all':
                return '';
        }
    }

    get st_index () {
        let {date: [start, end], annually} = this._criteria;
        let boxes = this.geometries.reduce ((a, g) => {
            a.push (getBbox (g));
            return a;
        }, []);
        //console.log("geometries :: ", this.geometries) 
        //console.log("boxes :: ", boxes)    
        return toQuery (stRange (start, end, boxes));
    }

    get query() { 
        //console.log("satellites :: ", this.satellites)       
        return [
            this.st_index,
            this.spatial,     
            this.date,
            this.clouds,            
            this.angle,
            this.stereo,
            this.satellites,                   
        ]
        .filter(x => x.trim() !== '')
        .join(' AND ');
    }

    get request () {
        //console.log("query :: ", this.query)
        return {
            layer: this._layer,
            orderby: 'acqdate',
            orderdirection: 'desc',
            geometry: true,
            page: 0,
            pagesize: 0,
            count: 1000,
            out_cs: 'EPSG:3857',
            query: this.query,
        };
    }

    search(limit = 0){
        return new Promise((resolve, reject) => {
            if (Array.isArray (this.geometries) && this.geometries.length <= SEARCH_GEOMETRIES_LIMIT) {
                let rq = {
                    ...this.request,
                    pagesize: limit
                }
                this._gmxResourceServer.sendPostRequest('VectorLayer/Search.ashx', rq)
                .then(response => {   
                    if(response.Status === 'ok'){
                        resolve(response.Result);
                    }
                    else {
                        reject(response);
                    }
                })
                .catch(e => {
                    const {ErrorInfo: {ErrorMessage = ''}} = e;
                    const exceptError = new Error(ErrorMessage);
                    reject(exceptError);
                });
            }
            else {
                const exceptError = new Error(Translations.translate('tooManyGeometries'));
                reject(exceptError);                
            }            
        });
    }

    searchByIDs(ids) {
        //console.log("searchByIDs :: ", ids)
        return new Promise((resolve, reject) => {  
            try {
                let list = '';
                if (Array.isArray(ids) && ids.length) {
                    list = ids.map(id => `'${id}'`).join(',');
                }
                else if (typeof ids === 'string') {
                    list = `'${ids}'`;
                }
                else {                    
                    throw new Error("id list not a string or array");
                }
                let rq = {
                    layer: this._layer,                    
                    orderby: 'acqdate',
                    orderdirection: 'desc',
                    geometry: true,
                    page: 0,
                    pagesize: 0,
                    count: 'add',
                    out_cs: 'EPSG:3857',
                    query: `sceneid IN (${list})`,                
                }
                this._gmxResourceServer.sendPostRequest('VectorLayer/Search.ashx', rq)
                .then(response => {   
                    if(response.Status === 'ok'){
                        resolve(response.Result);
                    }
                    else {
                        reject(response);
                    }
                })
                .catch(e => {
                    const {ErrorInfo: {ErrorMessage = ''}} = e;
                    const exceptError = new Error(ErrorMessage);
                    reject (exceptError);
                });
            }
            catch (e) {               
                reject(e);
            }            
        });
    }
}