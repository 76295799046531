<script>
    import * as Translations from '@scanex/translations';
    import {createEventDispatcher, onMount} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let satellites = [];
    export let unChecked = [];
    export let tmpUnchecked = [];
    export let sort = { field: 'acqdate', asc: false };
    export let isMouseHovered = false;
    export let isToggledHidden = true;

    let isSortHidden;
    $: {
        const {field = ''} = sort;    
        if (field === 'platform' || isMouseHovered) {
            isSortHidden = false;
        }
        else {
            isSortHidden = true;
        }        
    }

    $: sortMethod = !sort.asc ? 'down' : 'up';

    let checkedSatellites;
    $: {
        let checkedCount = 0;
        satellites.forEach(satellite => {
            const {platforms} = satellite;
            if (platforms.some(platform => unChecked.indexOf(platform) === -1)) {
                checkedCount += 1;
            }
        });
        checkedSatellites = checkedCount;
    }

    const onSortHover = () => isMouseHovered = true;
    const onSortOut = () => isMouseHovered = false;        

    const onSortClick = () => {    
        const {asc = false} = sort;
        dispatch('setSorted', {field: 'platform', asc: !asc});
    };

    const onHeaderClick = () => {
        if (isToggledHidden === false) {
            tmpUnchecked = [ ...unChecked ];
        }
        isToggledHidden = !isToggledHidden;
        dispatch('onHeaderClick', 'satellites');
    };

    const onApplyClick = () => {        
        unChecked = [ ...tmpUnchecked ];
        isToggledHidden = true;
        dispatch('changeClientFilter', {name: 'unChecked', value: unChecked});
    };

    const onCheckboxClick = (e, id) => {

        const {target} = e;
        const {checked} = target;        
        const currentPlatforms = _getSatellitePlatformsById(id);

        let newUnchecked = [ ...tmpUnchecked ];

        currentPlatforms.forEach(platform => {
            const unCheckedIndex = newUnchecked.indexOf(platform);
            if (!checked) {
                if (unCheckedIndex === -1) {
                    newUnchecked.push(platform);
                }
            }
            else {
                newUnchecked.splice(newUnchecked.indexOf(platform), 1);
            }
        });

        tmpUnchecked = newUnchecked;
    };

    const onAllCheckboxClick = e => {

        const {target} = e;
        const {checked} = target;        

        if (checked) {
            tmpUnchecked = [];
        }
        else {
            let platforms = [];
            satellites.forEach(item => {
                const currentPlatforms = item.platforms;
                currentPlatforms.forEach(platform => {
                    if (platforms.indexOf(platform) === -1){
                        platforms.push(platform);
                    }
                });
            });
            tmpUnchecked = platforms;
        }
    };

    const _getSatellitePlatformsById = satelliteId => {

        const currentSatellite = satellites.filter(item => item['_id'] === satelliteId);
        return currentSatellite[0]['_platforms'];
    }

    const isInUnchecked = (satellite, tmpUnchecked) => {
        return satellite.platforms.some(platform => tmpUnchecked.indexOf(platform) !== -1 );
    }        

</script>

<td
    on:mouseover="{onSortHover}"
    on:mouseout="{onSortOut}"
    style="padding-left:0;" class="table-list-col" data-field="platform"
>
    <div class="filterable-field-container">
        <div class="on-hover-div">
            <div class="filterable-applied">
                <div style="display: {unChecked.length > 0 ? 'block' : 'none'};">
                    <span class="checked">{checkedSatellites}</span>/<span class="all">{satellites.length}</span>
                </div>
            </div>
            <span
                on:click="{onHeaderClick}"
                class="filterable-header-platform filterable-header {isToggledHidden ? '' : 'active'} {unChecked.length > 0 ? 'applied' : ''}"
            >{translate('results.satellite')}</span>
            <i
                on:click="{onSortClick}"
                class="table-list-sort table-list-sort-{sortMethod}"
                style="visibility: {isSortHidden ? 'hidden' : 'visible'};"
            />
        </div>
        <div
            style="visibility: {isToggledHidden ? 'hidden' : 'visible'};"
            class="togglable-content-platform togglable-content filterable-satellites-container"
        >
            <fieldset class="search-options-satellites-ms">
                {#if satellites.length > 3}
                    <div class="satellite-col">
                        <input
                            on:click="{onAllCheckboxClick}"
                            checked={tmpUnchecked.length > 0 ? false : 'checked'}
                            type="checkbox"
                            id="sat_check_all"
                            class="all-checkbox"
                        />
                        <strong>{translate('filter.all')}</strong>
                    </div>
                {/if}
                {#each satellites as satellite}
                    <div class="satellite-col">
                        <input
                            on:click="{e => onCheckboxClick(e, satellite.id)}"
                            checked="{!isInUnchecked(satellite, tmpUnchecked) ? 'checked' : false}"
                            type="checkbox"
                            id="sat_{satellite.id}"
                            value={satellite.id}
                            class="s-checkbox"
                        />
                        <label for="sat_{satellite.id}">{satellite.name}</label>
                    </div>
                {/each}
            </fieldset>
            <div on:click="{onApplyClick}" class="apply">{translate('filter.apply')}</div>
        </div>
    </div>
</td>