import * as Translations from '@scanex/translations';
import BaseCompositedComponent from 'base/BaseCompositedComponent.js';
import SearchWidgetComponent from './components/searchWidget/SearchWidgetComponent.js';
import { ACCESS_USER_ROLE, TAB_SEARCH_NAME } from 'config/constants/Constants.js';
import View from './view/View.svelte';

export default class SearchTabComponent extends BaseCompositedComponent {

    init() {

        const panel = this._addTabToSidebar();

        this._view = new View({target: panel});

        this.initChildren([
            {
                index: 'searchWidget',
                constructor: SearchWidgetComponent
            }
        ]);

        this._setViewData();

        this._bindEvents();
    }

    _bindEvents() {

        const application = this.getApplication();
        const events = application.getServiceEvents();
        const store = application.getStore();
        const view = this.getView();

        events.on('sidebar:tab:resize', this._resizeSearchOptions.bind(this));
        store.on('currentTab:changeUI', this._onTabChangeHandler.bind(this));
        store.on('searchCriteria:update', this._setViewData.bind(this));
        view.$on('change', this.onCriteriaDataChange.bind(this));
        view.$on('search', this._onSearchButtonClick.bind(this));
    }

    _setViewData() {

        const application = this.getApplication();
        const store = application.getStore();
        const view = this.getView();

        const defaultCriteria = store.getData('searchCriteria');
        const userInfo = store.getData('userInfo');
        const restricted = userInfo['IsAuthenticated'] && (userInfo['Role'] === ACCESS_USER_ROLE || userInfo['Role'] === "admin");

        view.$set({ ...defaultCriteria, restricted });
    }

    onCriteriaDataChange({detail}) {

        const criteriaData = detail;
        const application = this.getApplication();
        const store = application.getStore();
        store.rewriteData('searchCriteria', criteriaData);
    }

    _onTabChangeHandler() {

        const application = this.getApplication();
        const store = application.getStore();
        const currentTab = store.getMetaItem('currentTab');

        if (currentTab === TAB_SEARCH_NAME) {
            this._setViewData();
            this._resizeSearchOptions();
        }
    }

    _resizeSearchOptions() {

        const view = this.getView();

        view.resize();
    }

    _onSearchButtonClick() {

        this.events.trigger('searchButton:click');
    }

    _addTabToSidebar() {

        const options = {
            id: 'search',
            icon: 'sidebar-search',
            opened: 'sidebar-search-opened',
            closed: 'sidebar-search-closed',
            tooltip: Translations.translate('search.title'), 
        };
        return this.getParentComponent().getView().addTab('search');
    }

    _isSomeSatellitesChecked() {

        const application = this.getApplication();
        const store = application.getStore();

        const searchCriteria = store.getData('searchCriteria');
        const {satellites: {pc = [], ms = []}} = searchCriteria;

        return ms.some(x => x.checked) || pc.some(x => x.checked);
    }

}