<script>

    import * as Translations from '@scanex/translations';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();
    export let isAuthentificated = false;

    const onApplyHandler = () => dispatch('apply');
    const onCloseHandler = () => dispatch('close');
</script>

<div>
    <div class="dialog-content">
        {#if !isAuthentificated}
            {@html translate('results.change')}
        {:else}
            {@html translate('results.download')}
        {/if}
    </div>
    <div class="dialog-footer">
        {#if !isAuthentificated}
            <button
                on:click="{onCloseHandler}"
                class="dialog-primary-button"
            >{translate('alerts.ok')}</button>
        {:else}
            <button
                on:click="{onApplyHandler}"
                class="dialog-primary-button"
            >{translate('download.ok')}</button>
            <button
                on:click="{onCloseHandler}"
                class="dialog-cancel-button"
            >{translate('download.cancel')}</button>
        {/if}
    </div>
</div>