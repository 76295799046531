<script>

    import * as Translations from '@scanex/translations';
    import {createEventDispatcher} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let hidden = true;
    export let loading = true;
    export let text = '';
    export let textInput;

    const onCancelClick = () => hidden = true;

    const onCopyClick = () => {        
        dispatch('copy', textInput);
        hidden = true;
    };

</script>

<div
    class="dialog-permalink"
    style="display: {hidden?'none':'block'}; top: 45px; left: 460px;"
>
    {#if !loading}
        <input bind:this="{textInput}" type="text" value="{text}" />
        <button on:click="{onCopyClick}" class="copy-button">{translate('alerts.clipboard')}</button>
    {:else}
        <span class="permalink-loader">{translate('alerts.wait')}</span>
    {/if}
    <button on:click="{onCancelClick}">{translate('alerts.cancel')}</button>
</div>