<script>

    import * as Translations from '@scanex/translations';
    import {createEventDispatcher} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let selectedIndex;
    export let drawingProps = [];
    export let preparedData = [];

    // computed
    let fullItem;
    $: firstProp = drawingProps.length > 0 ? drawingProps[0] : {};
    $: {        
        const {itemId, geoJSON: {properties} = {}, name} = firstProp;
        fullItem = { 'geometry': name, ...properties  };
    }
    $: itemKeys = Object.keys(fullItem);
    $: selected = selectedIndex ? selectedIndex : itemKeys[0];
    $: {
        const correctIndex = selected === 'geometry' ? 'name' : selected;
        preparedData = drawingProps.map(currentItem => {
            const {geoJSON: {properties = {}}} = currentItem;
            currentItem['selectedName'] = properties[correctIndex];
            return currentItem;
        });
    }

</script>


<div class="upload-dialog">
    <div class="header">
        {drawingProps.length > 0 ? translate('alerts.addToDrawingsHeader') : translate('alerts.wrongDrawings')}
    </div>
    {#if drawingProps.length > 0}
    <div class="panel-content">
        <div class="content">
            <div class="item-header">
                {firstProp['name']}
            </div>
            <div class="item-data">
                {#each itemKeys as index}
                    {#if typeof (fullItem[index]) !== 'object'}
                        <div class="item-container">
                            <input
                                type="radio"
                                class="item-radio"
                                checked="{index === selected}"
                                name="upload_radio_{fullItem[index]['itemId']}"
                                value="{fullItem[index]}"
                                on:change="{() => selectedIndex = index}"
                            />
                            <span>{index}:</span>
                            <span>{fullItem[index]}</span>
                        </div>
                    {/if}
                {/each}
            </div>
        </div>
    </div>
    {/if}
    <div class="panel-footer">
        {#if drawingProps.length > 0}
            <button
                class="dialog-upload-button"
                on:click="{() => dispatch('apply', preparedData)}">{translate('alerts.addToDrawings')}</button>
            <button
                on:click="{() => dispatch('cancel')}"
                class="dialog-cancel-button">{translate('alerts.cancel')}</button>
        {:else}
            <button class="dialog-upload-button" on:click="{() => dispatch('cancel')}">{translate('alerts.cancel')}</button>
        {/if}
    </div>
</div>

