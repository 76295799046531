import BaseComponent from 'base/BaseComponent.js';
import { createContainer } from 'utils/CommonUtils.js';
import View from './view/View.svelte';

export default class ResultListComponent extends BaseComponent {
    init() {
        this._view = new View({
            target: createContainer()
        });
    }
    shift(width) {
        this._view.$set({left: width});
    }
    show() {
        this._view.$set({hidden: false});
    }
    hide() {
        this._view.$set({hidden: true});
    }
}