import BaseUIElement from 'base/BaseUIElement.js';
import View from './view/View.js';

export default class AdvertisementUIElement extends BaseUIElement {

    init() {

        const map = this.getMap();
        const application = this.getApplication();

        this._view = new View({
            map,
            application,
            position: 'bottomright'
        });

        this._bindEvents();
    }

    _bindEvents() {

        const application = this.getApplication();
        const store = application.getStore();
        const DrawingController = application.getBridgeController('drawing');
        const view = this._view.widget;
    }

}