import BaseComponent from 'base/BaseComponent.js';
import { createContainer, getRootUrl } from 'utils/CommonUtils.js';
import View from './view/View.svelte';

export default class PermalinkFormComponent extends BaseComponent {

    init() {

        const container = createContainer();

        this._view = new View({
            target: container
        });

        this._bindEvents();
    }

    _bindEvents() {
        const view = this.getView();
        view.$on('copy', input => this.events.trigger('click:copy', input));
    }

    showLoading() {
        const view = this.getView();
        view.$set({
            hidden: false,
            loading: true,
            text: ''
        });
    }
    showInput(permalinkId) {

        const view = this.getView();
        const preparedUrl = `${getRootUrl()}?link=${permalinkId}`;

        view.$set({
            hidden: false,
            loading: false,
            text: preparedUrl
        });
    }
}