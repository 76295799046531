<script>
    import * as Translations from '@scanex/translations';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = (text, replaceBreaks = false) => {
        if (replaceBreaks) {
            return Translations.translate(text).replace(/\r\n/, '<br />');
        }
        return Translations.translate(text);
    };

    const dispatch = createEventDispatcher();

    const isInternalRestricted = ['Email', 'FullName', 'project', 'number', 'customer'];
    const defaultRestricted = ['Email', 'FullName'];

    export let permalink = '';
    export let items = '';
    export let scenesList = '';
    export let isAuthed = false;
    export let isInternal = false;
    export let isLoading = false
    export let isSuccess = false;
    // export let hasError = false;
    // export let errorText = '';
    export let inputValues = {
        FullName: '',
        Organization: '',
        Email: '',
        customer: '',
        project: '',
        number: '',
        comment: ''
    };
    export let cartType = 'commercial';
    export let inputErrors = {
        FullName: false,
        Email: false,
        customer: false,
        project: false,
        number: false
    };
    let isButtonValid;
    $: {
        isButtonValid = false;
        const {Email = '', FullName = '', project = '', number = '', customer = ''} = inputValues;
        if (isInternal) {
            const isProjectNumber = cartType !== 'commercial' ? true : number;
            isButtonValid = Boolean(Email && FullName && project && isProjectNumber && customer);
        }
        else {
            isButtonValid = Boolean(Email && FullName);
        }
    }

    let warningContainer;
    onMount(() => {        
        if (warningContainer) {
            const link = warningContainer.querySelector('.link');            
            link.addEventListener('click', () => dispatch('warning', permalink));
        }
    });

    const onLoginHandler = () => dispatch('login');
    const onCloseHandler = () => dispatch('close');            

    const onTextInputChange = (ev, fieldName) => {        
        const restrictedFields = isInternal ? isInternalRestricted : defaultRestricted;
        const {target: {value}} = ev;        
        inputValues[fieldName] = value;

        if (restrictedFields.indexOf(fieldName) !== -1 && !Boolean(value)) {
            if (fieldName !== 'number') {
                inputErrors[fieldName] = true;
            }
            else {
                inputErrors.number = cartType === 'commercial';
            }
        }
        else {
            inputErrors[fieldName] = false;
        }                
    };

    const onTextInputPaste = (ev, fieldName) => setTimeout(() => onTextInputChange(ev, fieldName), 0);

    const onSubmitClick = () => {
        if (!isButtonValid) {
            return;
        }

        isLoading = true;

        const dataToSend = getSendData();

        dispatch('submit', dataToSend);
    };

    export const showSuccess = () => {
        isSuccess = true;
        isLoading = false;
    };

    const getSendData = () => {        
        const projectType = getProjectType(isInternal ?  cartType : 'commercial');
        return {
            TinyReferenceUrl: permalink,
            ReceiveWay: '',
            Customer: inputValues['customer'],
            Project: isInternal ? inputValues['project'] : '',
            ProjectType: projectType,
            ContractNumber: isInternal ? inputValues['number'] : '',
            Name: '',
            Surname: inputValues['FullName'],
            Organization: isInternal ? inputValues['Organization'] : '',
            Email: inputValues['Email'],
            Phone: '',
            Comment: inputValues['comment'],
            Scenes: items,
            ScenesList: scenesList,
            Internal: isInternal,
            LangID: Translations.language,
        };                
    };

    const getProjectType = type => {
        switch (type) {
            case 'commercial':
                return 'К';
            case 'internal':
                return 'ВН';
            case 'presale':
                return 'ПС';
            default:
                throw 'unknown project type';
        }
    };
</script>

{#if !isAuthed}
<div class="auth-dialog">
    <div class="dialog-content" style="color:#8394a8;">
        {translate('alerts.authenticate')}
    </div>
    <div class="dialog-footer" style="padding-bottom:25px;">
        <button class="dialog-login-button" on:click|stopPropagation="{onLoginHandler}">{translate('alerts.login')}</button>
    </div>
</div>
{:else}
    {#if !isSuccess}
    <div class="noselect order-main-dialog">
        <div class="cart" style="border-radius:5px;">
            <div
                class="header"
                style="padding-top:10px; font-size:16px; text-align:left;"
            >{translate('cart.header')}</div>
            <div class="cart-order">
                <div class="cart-order-form">
                    <div
                        bind:this="{warningContainer}"
                        class="cart-order-warning"
                        style="text-align: center;"
                    >{@html translate('cart.warning', true)}</div>
                    <table>
                        <tbody>
                            <tr class="cart-customer">
                                <td>
                                    <label>{translate('cart.customer')}</label>
                                </td>
                                <td>
                                    <input
                                        disabled="{isLoading}"
                                        type="text"
                                        class="{inputErrors['customer'] ? 'invalid-field' : ''}"
                                        value="{inputValues['customer']}"
                                        on:keyup="{e => onTextInputChange(e, 'customer')}"
                                        on:paste="{e => onTextInputPaste(e, 'customer')}"
                                    />
                                </td>
                                <td>{isInternal ? '*' : ''}</td>
                            </tr>
                            {#if isInternal}
                            <tr class="cart-project">
                                <td>
                                    <label>{translate('cart.project.name')}</label>
                                </td>
                                <td>
                                    <input
                                        disabled="{isLoading}"
                                        type="text"
                                        class="{inputErrors['project'] ? 'invalid-field' : ''}"
                                        value="{inputValues['project']}"
                                        on:keyup="{e => onTextInputChange(e, 'project')}"
                                        on:paste="{e => onTextInputPaste(e, 'project')}"
                                    />
                                </td>
                                <td>*</td>
                            </tr>
                            <tr class="cart-project-type">
                                <td>
                                    <label>{translate('cart.project.type.title')}</label>
                                </td>
                                <td>
                                    <select bind:value="{cartType}" disabled="{isLoading}">
                                        <option value="commercial">{translate('cart.project.type.commercial')}</option>
                                        <option value="internal">{translate('cart.project.type.internal')}</option>
                                        <option value="presale">{translate('cart.project.type.presale')}</option>
                                    </select>
                                </td>
                                <td>*</td>
                            </tr>
                            <tr class="cart-project-number">
                                <td>
                                    <label>{translate('cart.project.number')}</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        disabled="{isLoading}"
                                        class="cart-project-number {cartType !== 'commercial' ? 'read-only' : ''} {inputErrors['number'] ? 'invalid-field' : ''}"
                                        value="{inputValues['number']}"
                                        readonly="{cartType !== 'commercial'}"
                                        on:keyup="{e => onTextInputChange(e, 'number')}"
                                        on:paste="{e => onTextInputPaste(e, 'number')}"
                                    />
                                </td>
                                <td>{cartType === 'commercial' ? '*' : ''}</td>
                            </tr>
                            {/if}
                            <tr class="cart-person">
                                <td>
                                    <label>{translate('cart.person')}</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        disabled="{isLoading}"
                                        class="{inputErrors['FullName'] ? 'invalid-field' : ''}"
                                        value="{inputValues['FullName']}"
                                        on:keyup="{e => onTextInputChange(e, 'FullName')}"
                                        on:paste="{e => onTextInputPaste(e, 'FullName')}"
                                    />
                                </td>
                                <td>*</td>
                            </tr>
                            {#if isInternal}
                            <tr class="cart-company">
                                <td>
                                    <label>{translate('cart.company')}</label>
                                </td>
                                <td>
                                    <input type="text" readonly value="{inputValues['Organization']}" />
                                </td>
                                <td />
                            </tr>
                            {/if}
                            <tr class="cart-email">
                                <td>
                                    <label>{translate('cart.email')}</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        disabled="{isLoading}"
                                        class="{inputErrors['Email'] ? 'invalid-field' : ''}"
                                        value="{inputValues['Email']}"
                                        on:keyup="{e => onTextInputChange(e, 'Email')}"
                                        on:paste="{e => onTextInputPaste(e, 'Email')}"
                                    />
                                </td>
                                <td>*</td>
                            </tr>
                            <tr class="cart-comment">
                                <td>
                                    <label>{translate('cart.comment')}</label>
                                </td>
                                <td>
                                    <textarea
                                        disabled="{isLoading}"
                                        maxlength="1000"
                                        on:keyup="{e => onTextInputChange(e, 'comment')}"
                                        on:paste="{e => onTextInputPaste(e, 'comment')}"
                                    >{inputValues['comment']}</textarea>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="cart-order-footer">
                    <button
                        disabled="{!isButtonValid || isLoading}"
                        class="cart-order-submit {!isButtonValid || isLoading ? 'button-disabled' : ''}"
                        on:click="{onSubmitClick}"
                    >{translate('cart.submit')}</button>
                </div>
            </div>
        </div>
    </div>
    {:else}
    <div class="auth-dialog">
        <div class="dialog-content" style="color:#8394a8; text-align: left;">
            <div>{translate('cart.success.header')}</div>
            <div>{translate('cart.success.content')}</div>
            <div>{translate('cart.success.footer')}</div>
        </div>
        <div class="dialog-footer" style="padding-bottom:25px;">
            <button
                on:click="{onCloseHandler}"
                class="dialog-login-button"
            >{translate('alerts.close')}</button>
        </div>
    </div>
    {/if}
{/if}