 <script>

    import Pikaday  from 'pikaday';
    import * as Translations from '@scanex/translations';
    import {compareDates, getNowDate} from 'utils/CommonUtils.js';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    const lang =  Translations.language || 'ru';

    export let date = getNowDate();
    export let mode = 'from';

    $: labelClass = `search-options-period-${mode}`;
    $: inputClass = `search-options-period-${mode}-value`;
    $: labelValue = translate(`period.${(mode === 'from' ? 'from' : 'to')}`);

    let _date;
    let input;

    const onChangeHandler = e => {
        const currentDate = _date.getDate();
        if (!compareDates(currentDate, date)) {
            dispatch('setDate', { date:currentDate });
        }
    };

    afterUpdate(() => {
        _date && _date.setDate(date);
    });

    onMount(() => {
        let i18n;
        switch (lang){
            case 'ru':
            default:
                moment.locale('ru');
                i18n = {
                    previousMonth : 'Предыдущий месяц',
                    nextMonth     : 'Следующий месяц',
                    months        : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
                    weekdays      : ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
                    weekdaysShort : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб']
                };
                break;
            case 'en':
                moment.locale('en');
                i18n = {
                    previousMonth : 'Previous Month',
                    nextMonth     : 'Next Month',
                    months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
                    weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
                    weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
                };
                break;
        }

        _date = new Pikaday({
            field: input,
            format: 'DD.MM.YYYY',
            yearRange: [2000, new Date().getFullYear().toString()],
            i18n,
            keyboardInput: false,
            blurFieldOnSelect: false,
        });

        _date.setDate(date);
    });

</script>

<div class="{labelClass}">{labelValue}</div>
<input on:change="{onChangeHandler}" bind:this="{input}" class="{inputClass}" type="text" />