<script>

    import * as Translations from '@scanex/translations';
    import {PANCHROME_IDS} from 'config/constants/Constants.js';
    import InfoWidget from './InfoWidget.svelte';
    import {createEventDispatcher, onMount, afterUpdate} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    const unitTranslate = Translations.translate('resolution.unit');

    const satellitesMeta = {
        first: {
            name: '0,3 - 0,5 ' + unitTranslate
        },
        second: {
            name: '0,7 - 2 ' + unitTranslate
        },
        third: {
            name: '>2 ' + unitTranslate
        },
        panchrome: {
            name: translate('satellite.panchrome')
        }
    };

    const prepareSatellites = (satellites, restricted, forCriteria = false) => {

        const {ms = [], pc = []} = satellites;
        const msSatellites = restricted ? ms : ms.filter(x => !x.restricted);
        const pcSatellites = restricted ? pc : pc.filter(x => !x.restricted);

        return !forCriteria ? [...msSatellites, ...pcSatellites] : {ms: msSatellites, pc: pcSatellites};
    };

    const getSatelliteSegment = (satellite) => {

        const {id, resolution} = satellite;

        if (!satellite.ms) {
            return 'panchrome';
        }

        if (resolution <= 0.5) {
            return 'first';
        }

        if(0.7 <= resolution && resolution <= 2) {
            return 'second';
        }

        if(resolution > 2) {
            return 'third';
        }
    };

    export let _satellites = {};
    export let hidden = {
        first: true,
        second: true,
        third: true,
        panchrome: true
    };
    export let restricted = false;

    $: satellitesForCriteria = prepareSatellites(_satellites, restricted, true);

    let preparedSatellites;
    $: {
        const rawSatellites = prepareSatellites(_satellites, restricted);

        let satellites = {
            first: {data:[], count: 0, allChecked: true},
            second: {data:[], count: 0, allChecked: true},
            third: {data:[], count: 0, allChecked: true},
            panchrome: {data:[], count: 0, allChecked: true}
        };

        rawSatellites.forEach(satellite => {

            const {id, checked, platforms, resolution} = satellite;
            const {first, second, third, panchrome} = satellitesMeta;
            const segmentIndex = getSatelliteSegment(satellite);

            satellites[segmentIndex]['data'].push(satellite);
            checked && (satellites[segmentIndex]['count'] += 1);
            !checked && (satellites[segmentIndex]['allChecked'] = false);
        });

        preparedSatellites = satellites;
    };

    let allCheckedCount;
    $: {
        const rawSatellites = prepareSatellites(_satellites, restricted);
        const checkedSatellites = rawSatellites.filter(item => item.checked);
        allCheckedCount = checkedSatellites.length;
    }
    let allVisible;
    $: {
        const { first, second, third, panchrome } = hidden;
        allVisible = !first && !second && !third && !panchrome;        
    }

    const toggleSection = segment => {        
        const currentHidden = hidden[segment];
        hidden[segment] = !currentHidden;        
    };

    const checkAllSatellites = (e, satellites, restricted, segment = false) => {

        const setChecked = (satellite, segment, checkedState) => {
            if (!segment) {
                satellite.checked = checkedState;
            }
            else {
                const {ids} = satellitesMeta[segment];
                const {id, restricted: currentRestricted} = satellite;
                let correctCheckedState = checkedState;
                if (currentRestricted && !restricted) {
                    correctCheckedState = false;
                }
                if (segment === getSatelliteSegment(satellite)) {
                    satellite.checked = correctCheckedState;
                }
            }
            return satellite;
        };

        e.stopPropagation();

        const {target: {checked: checkedState}} = e;
        const {ms = [], pc = []} = satellites;

        const checkedMs = ms.map(satellite => setChecked(satellite, segment, checkedState));
        const checkedPc = pc.map(satellite => setChecked(satellite, segment, checkedState));
    
        _satellites = {ms: checkedMs, pc: checkedPc};        
    };

    let infoWidget;
    const checkSatellite = (satellites, id) => {
        const setChecked = (satellite) => {
            const {id: currentId, checked} = satellite;
            if (currentId === id) {
                satellite.checked = !checked;
            }
            return satellite;
        };
        const {ms = [], pc = []} = satellites;
        const newMs = ms.map(satellite => setChecked(satellite));
        const newPc = pc.map(satellite => setChecked(satellite));    
        _satellites = {ms: newMs, pc: newPc};        
    };

    const showSatelliteInfo = (e, satellite) => {

        const {target} = e;
        const {resolution, swath, operator, since } = satellite;
        const {left, top, width} = target.getBoundingClientRect();        

        infoWidget.$set({
            left,
            top,
            width,
            resolution,
            since,
            swath,
            operator,
            hidden: false
        });
    };

    const hideSatelliteInfo = (e, satellite) => {
        infoWidget.$set({ hidden: true });
    };

    const getCurrentName = segment => satellitesMeta[segment]['name'];

    afterUpdate(() => {                
        dispatch('changeSatellites', {_satellites});
    });

</script>

<div class="no-select search-options-satellites" style="padding-right:10px;">
    {#each Object.keys(preparedSatellites) as segment}
        {@debug segment}
        <div class="satellite-segment-container">
            <div on:click="{() => toggleSection(segment)}" class="satellite-header {hidden[segment] ? 'closed' : 'opened'}">
                <span>
                    <input
                        checked="{preparedSatellites[segment]['allChecked']}"
                        indeterminate="{!preparedSatellites[segment]['allChecked'] && preparedSatellites[segment]['count'] > 0}"
                        type="checkbox"
                        on:click="{e => checkAllSatellites(e, _satellites, restricted, segment)}"
                    />
                </span>
                <span>{getCurrentName(segment)}</span> <div class="count">{preparedSatellites[segment]['count']}</div>
                <div class="toggleControl {hidden[segment] ? 'closed' : 'opened'}" />
            </div>
            {#if !hidden[segment]}
                {#each preparedSatellites[segment].data as satellite}
                    <div style="padding:5px 5px 5px 25px;">
                        <input
                            type="checkbox"
                            id="sat_{satellite.id}"
                            checked="{satellite.checked}"
                            on:change="{() => checkSatellite(_satellites, satellite.id)}"
                        />
                        <label
                            style="vertical-align: middle; font-size:13px;" for="sat_{satellite.id}"
                            on:mouseover="{e => showSatelliteInfo(e, satellite)}"
                            on:mouseout="{hideSatelliteInfo}"
                        >{satellite.name}</label>
                    </div>
                {/each}
            {/if}
        </div>
    {/each}
    <div class="all-count">{allCheckedCount}</div>
    <InfoWidget bind:this="{infoWidget}" />
</div>