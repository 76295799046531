<script>
    import * as Translations from '@scanex/translations';
    import {createEventDispatcher, onMount} from 'svelte';

    const dispatch = createEventDispatcher();

    export let annually = false;

    const onClickHandler = () => dispatch('setAnnually', {annually: !annually});
</script>

<div class="search-options-annually-container" style="position: absolute; top:23px; right:30px;">
    <div
        title='Режим "Ежегодно" {annually ? "включен" : "выключен"}'
        on:click="{onClickHandler}"
        class="search-options-annually-control {annually ? 'annually-checked' : 'annually-unchecked'}"
    />
</div>