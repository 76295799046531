<script>
    import * as Translations from '@scanex/translations';
    import RangeWidget from '../extended/ExtendedSliderwidget.js';
    import {createEventDispatcher, onMount} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let sort = { field: 'acqdate', asc: false };
    export let isMouseHovered = false;
    export let isToggledHidden = true;
    export let value = [0, 100];
    export let tmpValue = [0, 100];
    export let clouds = [0, 100];

    let sliderContainer;
    let isSortHidden;

    $: {
        const {field = ''} = sort;    
        if (field === 'cloudness' || isMouseHovered) {
            isSortHidden = false;
        }
        else {
            isSortHidden = true;
        }        
    }
    $: sortMethod = !sort.asc ? 'down' : 'up';
    $: isFilterApplied = (value[0] !== clouds[0] || value[1] !== clouds[1]);    

    let _slider;
    onMount(() => {        
        const limits = {min: clouds[0], max: clouds[1]};
        _slider = new RangeWidget(sliderContainer, limits);
        _slider.values = value;
        _slider._bar.querySelector('.slider-widget-range').style.width = '215px';
        _slider.addEventListener('stop', onSliderStopHandler);
    });

    const onFilterHover = e => e.stopPropagation();
    const onSortHover = () => isMouseHovered = true;
    const onSortOut = () => isMouseHovered = false;
    const onSortClick = () => {        
        const {asc = false} = sort;
        dispatch('setSorted', {field: 'cloudness', asc: !asc});
    };
    const onApplyClick = () => {        
        value = [...tmpValue];
        isToggledHidden = true;
        dispatch('changeClientFilter', {name: 'clouds', value: tmpValue});
    };

    const onHeaderClick = () => {            
        if (isToggledHidden === false) {
            _slider.values = value;
        }
        isToggledHidden = !isToggledHidden;
        dispatch('onHeaderClick', 'clouds');
    };

    const onSliderStopHandler = e => {
        const {detail = [0, 100]} = e;
        tmpValue = detail;
    };

    export const clearFilter = c => {
        isToggledHidden = true;
        clouds = c;
        value = clouds;
        tmpValue = clouds;
        _slider.values = clouds;
        _slider.setLimits(clouds[0], clouds[1]);
        const rangeWidth = _slider._bar.querySelector('.slider-widget-range').style.width;
        _slider._bar.querySelector('.slider-widget-range').style.width = '215px';
    };
    

</script>

<td
    on:mouseover="{onSortHover}"
    on:mouseout="{onSortOut}"
    style="padding-left:3px;" class="table-list-col" data-field="cloudness"
>
    <div class="filterable-field-container">
        <div class="on-hover-div">
            <div class="filterable-applied">
                <div style="display: {isFilterApplied ? 'block' : 'none'};">
                    <span class="min">{value[0]}</span>-<span class="max">{value[1]}</span>
                </div>
            </div>
            <span
                on:click="{onHeaderClick}"
                class="filterable-header-platform filterable-header {isToggledHidden ? '' : 'active'} {isFilterApplied  ? 'applied' : ''}"
            >{translate('results.clouds')}</span>
            <i
                on:click="{onSortClick}"
                class="table-list-sort table-list-sort-{sortMethod}"
                style="visibility: {isSortHidden ? 'hidden' : 'visible'};"
            />
        </div>
        <div
            on:mouseover="{onFilterHover}"
            style="visibility: {isToggledHidden ? 'hidden' : 'visible'}; padding-top:10px;"
            class="togglable-content-cloudness togglable-content filterable-cloudness-container"
        >
            <div style="text-align: left;">
                <input class="extended-slider-input min-input" type="text" value={value[0]} />
                -
                <input class="extended-slider-input max-input" type="text" value={value[1]} />
            </div>
            <div class="results-cloudness-slider-container" bind:this="{sliderContainer}"></div>
            <div class="min-value">{clouds[0]}</div>
            <div class="max-value">{clouds[1]}</div>
            <div style="clear: both;"></div>
            <div on:click="{onApplyClick}" class="apply">{translate('filter.apply')}</div>
        </div>
    </div>
</td>