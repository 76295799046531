<script>
    import * as Translations from '@scanex/translations';
    import RangeWidget from '../extended/ExtendedSliderwidget.js';
    import {createEventDispatcher, onMount} from 'svelte';

    const translate = Translations.translate.bind(Translations);
    const dispatch = createEventDispatcher();

    export let sort = { field: 'acqdate', asc: false };
    export let isMouseHovered = false;
    export let isToggledHidden = true;
    export let value = [0, 80];
    export let tmpValue = [0, 80];
    export let angle = [0, 80];

    let sliderContainer;
    let isSortHidden;
    $: {
        const {field = ''} = sort;                
        if (field === 'tilt' || isMouseHovered) {
            isSortHidden = false;
        }
        else {
            isSortHidden = true;
        }
    }
    $: sortMethod = !sort.asc ? 'down' : 'up';
    $: isFilterApplied = (value[0] !== angle[0] || value[1] !== angle[1]);

    let _slider;
    onMount(() => {        
        const limits = {min: angle[0], max: angle[1]};
        _slider = new RangeWidget(sliderContainer, limits);
        _slider.values = value;
        _slider._bar.querySelector('.slider-widget-range').style.width = '215px';
        _slider.addEventListener('stop', onSliderStopHandler);
    });

    const onFilterHover = e => e.stopPropagation();
    const onSortHover = () => isMouseHovered = true;
    const onSortOut = () => isMouseHovered = false;

    const onSortClick = () => {
        const {asc = false} = sort;
        dispatch('setSorted', {field: 'tilt', asc: !asc});
    };

    const onApplyClick = () => {        
        value = [...tmpValue];
        isToggledHidden = true;
        dispatch('changeClientFilter', {name: 'angle', value: tmpValue});
    };

    const onHeaderClick = () => {            
        if (isToggledHidden === false) {
            _slider.values = value;
        }
        isToggledHidden = !isToggledHidden;
        dispatch('onHeaderClick', 'angles');
    };

    const onSliderStopHandler = e => {
        const {detail = [0, 100]} = e;
        tmpValue = detail;
    };

    export const clearFilter = a => {
        angle = a;
        isToggledHidden = true;
        value = angle;
        tmpValue = angle;        
        _slider.values = angle;
        _slider.setLimits(angle[0], angle[1]);
        _slider._bar.querySelector('.slider-widget-range').style.width = '215px';
    };

</script>

<td
    on:mouseover="{onSortHover}"
    on:mouseout="{onSortOut}"
    style="padding-left:3px;" class="table-list-col" data-field="tilt"
>
    <div class="filterable-field-container">
        <div class="on-hover-div">
            <div class="filterable-applied">
                <div style="display: {isFilterApplied ? 'block' : 'none'};">
                    <span class="min">{value[0]}</span>-<span class="max">{value[1]}</span>
                </div>
            </div>
            <span
                on:click="{onHeaderClick}"
                class="filterable-header-angle filterable-header {isToggledHidden ? '' : 'active'} {isFilterApplied  ? 'applied' : ''}"
            >{translate('results.angle')}</span>
            <i
                on:click="{onSortClick}"
                class="table-list-sort table-list-sort-{sortMethod}"
                style="visibility: {isSortHidden ? 'hidden' : 'visible'};"
            />
        </div>
        <div
            on:mouseover="{onFilterHover}"
            style="visibility: {isToggledHidden ? 'hidden' : 'visible'}; padding-top:10px;"
            class="togglable-content-angle togglable-content filterable-cloudness-container"
        >
            <div style="text-align: left;">
                <input class="extended-slider-input min-input" type="text" value={value[0]} />
                -
                <input class="extended-slider-input max-input" type="text" value={value[1]} />
            </div>
            <div class="results-angle-slider-container" bind:this="{sliderContainer}"></div>
            <div class="min-value">{angle[0]}</div>
            <div class="max-value">{angle[1]}</div>
            <div style="clear: both;"></div>
            <div on:click="{onApplyClick}" class="apply">{translate('filter.apply')}</div>
        </div>
    </div>
</td>

