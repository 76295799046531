<script>

    import * as Translations from '@scanex/translations';
    import {createEventDispatcher} from 'svelte';

    export let mode = 'alert';
    export let headerText = '';
    export let messageText = '';

    const dispatch = createEventDispatcher();
    const translate = Translations.translate.bind(Translations);

</script>

{#if mode === 'alert'}
<div>
    <div class="dialog-content">{messageText}</div>
    <div class="dialog-footer">
        <button
            on:click="{() => dispatch('close')}"
            class="dialog-primary-button"
        >{translate('alerts.close')}</button>
    </div>
</div>
{:else if mode === 'warning'}
<div>
    <div class="dialog-content">{messageText}</div>
    <div class="dialog-footer">
        <button on:click="{() => dispatch('close')}" class="dialog-primary-button">{translate('alerts.close')}</button>
    </div>
</div>
{:else if mode === 'error'}
<div>
    <div class="dialog-content">
        {#if headerText}
            {headerText}<br />
        {/if}
        {messageText}
    </div>
    <div class="dialog-footer">
        <button on:click="{() => dispatch('close')}" class="dialog-primary-button">{translate('alerts.close')}</button>
    </div>
</div>
{/if}