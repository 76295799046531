import * as Translations from '@scanex/translations';

Translations.add('ru', {
    about: {
        version: 'Версия',
        news: 'Что нового',
        help: 'Инструкция пользователя'
    },
    
});

Translations.add('en', {
    about: {
        version: 'Version',
        news: "What's new",        
        help: "User's guide"
    }
    
});